import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import { Subscription } from 'rxjs';
import {
  fetchProductDetail,
  fetchProductDetailVersion,
  productCheckout,
} from '../../store/dashboard.actions';
import { AdditionalDetails, ProductsDetail } from 'src/app/model/Products';
import { allCoupons } from 'src/app/features/profilo/store/profilo.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, take } from 'rxjs/operators';
import { Auth } from '@angular/fire/auth';

@Component({
  selector: 'app-id-percorso',
  templateUrl: './id-percorso.component.html',
  styleUrls: ['./id-percorso.component.scss'],
})
export class IdPercorsoComponent implements OnInit, OnDestroy {
  private dashboardSub: Subscription;
  private profileSub: Subscription;
  private paramSubscription: any;
  private coupon: any;
  public productId: string = '';
  private paramId: string = '';
  public productCode: string;
  public productDetails: ProductsDetail | null;
  public additionalDetails: AdditionalDetails | null;
  public loading: boolean = false;
  activeTab: string = 'individuale';
  products_family: any;
  showSection: boolean = false;
  public isLoggedIn: boolean = false;

  constructor(
    private auth: Auth,
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.productId = params['productId']; // Recupera productId dai parametri di query
      //console.log(productId, "productID")
      if (this.productId) {
        console.log('Product ID from query params:', this.productId);
        setTimeout(() => this.payOrder(this.productId), 1000);
      }
    });

    //this.store.dispatch(allCoupons());
    this.paramSubscription = this.route.paramMap.subscribe((params) => {
      const idParam = params.get('id');
      //console.log(idParam, "idParam");
      if (idParam) {
        this.paramId = idParam;
        this.store.dispatch(
          fetchProductDetail({
            productId: this.paramId,
          })
        );
        this.http
          .get<any>(
            `${environment.BFF_PATIENT}/products/by-code/${idParam}_fam`
          )
          .subscribe((data) => {
            this.products_family = data;
            console.log(this.products_family, 'products family');
          });
      } else {
        this.router.navigate(['/home']);
      }

      // verifica se l'utente è loggato
      this.auth.onAuthStateChanged((user) => {
        this.isLoggedIn = !!user;
        // console.log(this.isLoggedIn);
      });
    });
    this.dashboardSub = this.store
      .select('dashboard')
      .subscribe((dashboard) => {
        this.productDetails = dashboard.productDetails;
        this.additionalDetails = dashboard.additionalDetails;
        this.loading = dashboard.loading;
      });
  }

  ngOnDestroy(): void {
    this.dashboardSub && this.dashboardSub.unsubscribe();
    this.profileSub && this.profileSub.unsubscribe();
    this.paramSubscription && this.paramSubscription.unsubscribe();
  }

  payOrder(productId: any) {
    const pathIndex = window.location.href.lastIndexOf('/');
    const pathSuccess = new URL(window.location.href).origin;
    let pathCancel = window.location.href;

    //FIX brutto ma giusto
    if (pathCancel.includes('membership_firstcisl')) {
      pathCancel = `${environment.site_url_fix}/membership/membership_firstcisl`;
    } else if (pathCancel.includes('membership_ambassador')) {
      pathCancel = `${environment.site_url_fix}/membership/membership_ambassador`;
    } else if (pathCancel.includes('memb_promo')) {
      pathCancel = `${environment.site_url_fix}/membership/memb_promo`;
    }

    const checkOut: any = {
      productId: productId,
      successUrl: pathSuccess + '/success',
      cancelUrl: pathCancel,
    };
    if (this.coupon && this.coupon.amount > 1.0) {
      checkOut.couponCode = this.coupon.code;
    }
    this.store.dispatch(productCheckout(checkOut));

    localStorage.setItem('productType', this.productDetails!.type);
  }

  getProductDetails(versionId: any): void {
    this.store.dispatch(
      fetchProductDetailVersion({
        versionId: versionId,
      })
    );
  }

  goBack() {
    this.location.back();
  }

  goToPayWall() {
    const url = 'https://app.drfeel.com/subscribe';
    window.open(url, '_self');
  }

  setActiveTab(tab: string): void {
    this.activeTab = tab;
  }

  navigateToSignup() {
    const currentUrl = this.router.url;
    const queryParams = this.route.snapshot.queryParams;

    // console.log('currentUrl', currentUrl);
    // console.log('queryParams', queryParams);

    this.router.navigate(['/signup'], {
      queryParams: {
        redirectTo: encodeURIComponent(currentUrl),
      },
    });
  }
}
