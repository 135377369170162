import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { resetPrenotazioni } from '../../prenotazione/store/actions/prenotazione.action';
import * as fromApp from '../../../store/app.reducer';
import * as RichiesteActions from '../store/richieste.actions';
import { Richiesta, RichiestaDatum } from 'src/app/model/Richiesta';
import { environment } from 'src/environments/environment';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';

@Component({
  selector: 'app-richieste',
  templateUrl: './richieste.component.html',
  styleUrls: ['./richieste.component.scss'],
})
export class RichiesteComponent implements OnInit, OnDestroy {
  viewMode: string = 'richieste';
  private storeSubscription: Subscription;
  private profileSubscription: Subscription;
  public richieste: Richiesta | null;
  public isLoading: boolean = true;
  public error: boolean = false;
  public errorMessage: string = '';
  public richiestaId: string;
  public isOneActive: boolean = false;
  public loading: boolean = false;
  public documentsFromStore: ProfiloDocumentDatum[];
  public allDocuments: ProfiloDocumentDatum[];
  public idDocs: string[] = [];
  public typeDocSelected: string = '';
  public filtredDocs: ProfiloDocumentDatum[];
  public richiestaDate: string;
  public richiestaAmount: number;

  @ViewChild('modal2', { static: true }) modal2: ElementRef;
  @ViewChild('openModalSuccess', { static: true }) openModalSuccess: ElementRef;
  @ViewChild('modalerror', { static: true }) modalerror: ElementRef;

  goto(value: string) {
    this.viewMode = value;
  }

  constructor(private router: Router, private store: Store<fromApp.AppState>) {}

  handleDetailClick(clickedDoctor: RichiestaDatum) {
    this.router.navigate(['/richiesta', clickedDoctor.id]);
  }

  getHumanDate = (date: any, nextYear = false, nextDay = false) => {
    const dd = nextDay
      ? String(date.getDate() + 1).padStart(2, '0')
      : String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = nextYear ? date.getFullYear() + 1 : date.getFullYear();
    const humanDate = yyyy + '-' + mm + '-' + dd;
    return humanDate;
  };

  ngOnInit(): void {
    this.store.dispatch(
      RichiesteActions.fetchRequests({
        searchRequest: true,
        statusValue: 'OPEN',
        fromDate: this.getHumanDate(new Date()),
        toDate: this.getHumanDate(new Date(), true, false),
      })
    );

    //
    this.store.dispatch(RichiesteActions.fetchOrders());
    this.storeSubscription = this.store
      .select('richieste')
      .subscribe((richieste) => {
        this.richieste = richieste.requests;
        this.isLoading = richieste.loading;
        this.error = richieste.error;
        this.errorMessage = richieste.errorMessage;
        console.log('richieste from richieste component', this.richieste);
        if (this.richieste) {
          this.isOneActive = this.richieste.data.some(
            (richiesta) => richiesta.status === 'OPEN'
          );
        }
      });
    this.profileSubscription = this.store
      .select('profile')
      .subscribe((profile) => {
        this.allDocuments = profile.documents;
        // this.isLoading = profile.loading;
      });
  }

  expireDate(dateString: string): boolean {
    let oggi = new Date().getTime();
    let dataVisita = new Date(dateString).getTime();
    let differenza = dataVisita - oggi - 21600000; // limite 6 ore = 21600000 ms
    if (differenza < 0) {
      return true;
    } else {
      return false;
    }
  }

  checkFreeRequest(richiestaAmount: number): boolean {
    if (richiestaAmount === 0) {
      return true;
    } else {
      return false;
    }
  }

  public expireQuestionario(data: any) {
    return true;
    let dataSalvata = new Date(data);
    let dataAttuale = new Date();
    if (dataAttuale > dataSalvata) {
      return false;
    } else {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.storeSubscription && this.storeSubscription.unsubscribe();
    this.profileSubscription && this.profileSubscription.unsubscribe();
  }

  onRichiestaClick(richiesta: RichiestaDatum) {
    this.router.navigate(['/medic', richiesta.doctor.id]);
  }

  getDoctorAvatar(richiesta: RichiestaDatum) {
    return `${environment.NEW_API}/doctors/${richiesta.doctor.id}/image`;
  }

  toPrenotazione() {
    this.store.dispatch(resetPrenotazioni());
    this.router.navigate(['/prenotazione']);
  }

  setRichiestaId(richiestaId: string, richiestaDate: string, richiestaAmount: number) {
    this.richiestaId = richiestaId;
    this.richiestaDate = richiestaDate;
    this.richiestaAmount = richiestaAmount;
  }

  deleteRichiesta() {
    this.error = false;
    this.errorMessage = "";
    this.store.dispatch(
      RichiesteActions.deleteRichiesta({ id: this.richiestaId })
    )
    const bsAlertContainer = document.querySelector('.bs-alert-container');
    if (this.error && bsAlertContainer && !bsAlertContainer.innerHTML.includes('alert')) {
      bsAlertContainer.innerHTML = `
      <div class="alert alert-danger alert-dismissible show d-flex align-items-center mt-5" role="alert">
        <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
          <use xlink:href="#exclamation-triangle-fill" />
        </svg>
        <div>
          ${this.errorMessage}
        </div>
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      `
    }
  }

  onCloseModal() {
    this.modal2.nativeElement.click();
  }
   onSuccessModal() {
    this.openModalSuccess.nativeElement.click();
  }
  onFailModal() {
    this.modalerror.nativeElement.click();
  }

  openDocs(id: string) {
    this.typeDocSelected = '';
    this.filtredDocs = [];
    this.richiestaId = id;
    this.store.dispatch(
      RichiesteActions.fetchRichiestaDocuments({
        richiestaId: id,
      })
    );
    this.store.dispatch(ProfileActions.fetchDocuments());
    this.storeSubscription = this.store
      .select('richieste')
      .subscribe((richieste) => {
        this.idDocs = richieste.idDocs; //id dei documenti presenti nella richiesta
        this.documentsFromStore = richieste.selectedRichiestaDocuments;
        this.loading = richieste.docsLoading;
      });
  }

  onOptionSelected() {
    console.log('QUI', this.typeDocSelected);
    this.filtredDocs = this.allDocuments.filter(
      (doc) => doc.documentType == this.typeDocSelected.toUpperCase()
    );
    if (this.filtredDocs.length > 0) {
      this.filtredDocs = this.filtredDocs.filter(
        (doc) => !this.isDocShared(doc.id)
      );
    }
  }

  isDocShared = (id: string) => this.idDocs.includes(id);

  linkDocIdToRichiesta(docId: string) {
    this.store.dispatch(
      RichiesteActions.linkDocToRequest({
        requestId: this.richiestaId,
        documentId: docId,
      })
    );
    this.modal2.nativeElement.click();
  }
}
