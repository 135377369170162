<div class="survey-container drfeel-border p-3 mt-4">
  <div class="left">
    <div>
      <h1 class="do3-fw800 text-gradient-drfeel">
        Migliora il tuo stato di salute e benessere
      </h1>

      <p class="my-health-description">
        Con MyHealth ottieni una valutazione complessiva della tua salute, della
        tua alimentazione e del tuo stato di benessere mentale e fisico.
      </p>
    </div>

    <div>
      <h3 class="do3-fw800">Inizia ora, compila i questionari.</h3>

      <app-loading-spinner *ngIf="loading"></app-loading-spinner>

      <ng-container *ngIf="!loading && surveys && surveys.data.length > 0">
        <ng-container *ngFor="let survey of surveys?.data">
          <a class="card survey-card drfeel-border" [href]="'/anamnesi/' + survey.id">
            <div class="survey-body">
              <div class="survey-left">
                <div class="survey-icon">
                  <img [src]="
                      'assets/images/kpi/kpi-' +
                      handleType(survey.specialization.description) +
                      '.svg'
                    " alt="survey icon" class="circle" />
                </div>
                <div class="survey-text">
                  <h3 class="survey-title do3-fw800">
                    {{ handleType(survey.specialization.description) }}
                  </h3>
                  <p class="survey-subtitle" *ngIf="survey.hasTriage">
                    Completato
                  </p>
                  <p class="survey-subtitle" *ngIf="!survey.hasTriage">
                    Da completare
                  </p>
                </div>
              </div>
              <div class="survey-right">
                <img *ngIf="survey.hasTriage" class="survey-circle" [src]="'assets/images/kpi/kpi-done.svg'" />
                <!-- <img
                  *ngIf="!survey.hasTriage"
                  class="survey-circle"
                  [src]="'assets/images/kpi/kpi-incomplete.svg'"
                /> -->
                <mat-icon>arrow_forward_ios</mat-icon>
              </div>
            </div>
          </a>
        </ng-container>
      </ng-container>
    </div>
    <!-- Questionari container fine -->
  </div>

  <div class="right">
    <ng-container *ngIf="kpiUrl">
      <div class="health-score">
        <img class="w-100 pb-4" [src]="kpiUrl" alt="health score" />
      </div>
    </ng-container>
    <ng-container *ngIf="!kpiUrl">
      <div class="health-score">
        <img *ngIf="!kpiUrl" [src]="'assets/images/kpi/kpi-default.svg'" alt="health score" />
        <p class="do3-fw800 my-1">MyHealth score</p>
        <div #tooltipElement>
          <button class="info-btn" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true"  data-bs-custom-class="custom-tooltip"
            title="Il <span class='drfeel-color'><b>MyHealth score</b></span> è il primo indicatore di salute che calcola la qualità del tuo stile di vita attraverso la combinazione di informazioni sulle tue abitudini alimentari, sportive e sul tuo stato di benessere mentale e fisico. Attraverso il MyHealth score il tuo medico potrà elaborare percorsi personalizzati per aiutarti a migliorare il tuo stile di vita e raggiungere i tuoi obiettivi di salute.">
            <h3 class="do3-fw800 m-0">i</h3>
          </button>
        </div>
      </div>
      <div class="health-survey" *ngIf="!allTriage">
        <h2 class="do3-fw800">Non hai ancora compilato i questionari.</h2>
        <p>Rispondi alle domande per generare il MyHealth score.</p>
      </div>
      <div class="health-survey" *ngIf="allTriage">
        <h2 class="do3-fw800">Congratulazioni,<br>hai completato i questionari!</h2>
        <p>I nostri medici stanno analizzando le risposte.</p>
        <p>Nei prossimi giorni potrai visualizzare qui il MyHealth score e il tuo Medico ti presenterà i risultati.</p>
      </div>
    </ng-container>
  </div>
</div>