import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
// import { changeProfilePic } from 'src/app/features/profilo/store/profilo.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as ProfileActions from 'src/app/features/profilo/store/profilo.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-avatar',
  templateUrl: './change-avatar.component.html',
  styleUrls: ['./change-avatar.component.scss'],
})
export class ChangeAvatarComponent implements OnInit {
  // @Input() isRichiesta: boolean;
  @Input() richiestaId: string = '';
  public fileToUpload: File | null = null;
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files = [];
  private postUrl: string = environment.NEW_API + '/profile/image';
  private payload: any = {
    headers: {
      contentType: 'multipart/form-data',
    },
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnInit(): void {}

  uploadImageProfile(file: any) {
    const formData = new FormData();
    formData.append('image', file.data, file.data.name);
    return this.httpClient
      .post(this.postUrl, formData, this.payload)
      .subscribe((data) => {
        console.log('success', data);
        // this.store.dispatch(ProfileActions.fetchImageProfile());
        this.router.navigateByUrl('/profilo');

      });
  }

  onClick(): void {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      const fileSingle = fileUpload.files[0];
      const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
      this.uploadImageProfile(fileParams);
    };
    fileUpload.click();
  }

  // changeAvatar(url: any) {
  //   this.store.dispatch(changeProfilePic({ profilePic: url }));
  //   console.log('changeAvatar', url);
  // }

  // onClick(): void {
  //   const fileUpload = this.fileUpload.nativeElement;
  //   fileUpload.onchange = () => {
  //     const fileSingle = fileUpload.files[0];
  //     const fileParams = { data: fileSingle, inProgress: false, progress: 0 };
  //     const filePath = 'avatar';
  //     const ref = this.storage.ref(filePath);
  //     const task = ref.put(fileSingle);
  //     //   const task = this.storage.upload(filePath, fileSingle);
  //     //   this.changeAvatar(fileParams);
  //     console.log('storage task', task);
  //   };
  //   fileUpload.click();
}
