<div class="do3-gray-text f-size-smaller">
  <div class="container mt-5">
    <div class="row do3-medic-top">
      <div class="col d-flex align-items-center ml-4">
        <button class="do3-back-btn col-12" (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
          Torna indietro
        </button>
      </div>
      <div class="col" *ngIf="loggedIn">
        <div class="container">
          <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>
        </div>
      </div>
    </div>

    <app-loading-spinner class="row d-flex justify-content-center" *ngIf="loading"></app-loading-spinner>

    <div class="row flex-column-reverse flex-lg-row do3-medic-content" *ngIf="!loading && doctorFromStore">
      <div class="col-12 col-lg-4 col-xl-3 left-side mt-5">
        <div class="container right-border">
          <div class="mt-4">
            <div *ngIf="doctorFromStore.location">
              <h1 class="m-0">Sede:</h1>
              <ul class="mb-3">
                <li class="do3-lightblue-text mb-3" *ngFor="let address of doctorFromStore.location">
                  <p>{{ address.description }}</p>
                  <p class="do3-gray-text">{{ address.street }} - {{ address.city }}</p>
                </li>
              </ul>
            </div>
            <div *ngIf="shouldShowIllnessList">
              <h1 class="m-0">Patologie comuni trattate:</h1>
              <ul>
                <ng-container *ngFor="let patologia of doctorFromStore.illness">
                  <li class="mb-1 do3-lightblue-text" *ngIf="!patologia.name.startsWith('HIDE-')">
                    <p class="do3-gray-text">{{ patologia.name }}</p>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>

      </div>
      <div class="col-12 col-lg-8 col-xl-9 right-side">
        <div class="w-85 right-container mt-5">
          <div class="d-flex flex-column flex-sm-row t-4 mb-4">
            <div class="img-profile-container large bolder-border me-3">
              <img [src]="getDoctorAvatar()" class="img-profile" [alt]="doctorFromStore.name">
            </div>

            <div class="ml-4 pr-3">
              <h1 class="do3-blue-text fw-bold mb-0">Medico:</h1>
              <h3 class="do3-lightblue-text fw-bold mb-0">
                <span *ngIf="doctorFromStore.gender === 'FEMALE'">Dott.ssa</span>
                <span *ngIf="doctorFromStore.gender === 'MALE'">Dr.</span>
                {{ doctorFromStore.name }}
              </h3>
            </div>
            <div class="ml-3 pr-3">
              <h1 class="do3-blue-text fw-bold mb-0" *ngIf="doctorFromStore.specializzazioni">
                {{ doctorFromStore.specializzazioni.length == 1 ? 'Specialistica:' : 'Specialistiche:' }} </h1>
              <h3 class="do3-lightblue-text fw-bold mb-0" *ngFor="let spec of doctorFromStore.specializzazioni">
                {{ spec.name }}
              </h3>
            </div>
          </div>
          <!-- Containers to remove/add -->
          <div class="overflow-auto doc-text">
            <div>
              <div *ngIf="doctorFromStore.cv">
                <p>
                  {{doctorFromStore.cv}}
                </p>
              </div>

              <div *ngIf="doctorFromStore.education">
                <h1 class="mb-0">Istruzione e Formazione</h1>
                <ul>
                  <li class="mb-0" *ngFor="let ed of doctorFromStore.education">
                    {{ ed.period ? ed.period + ' - ' : '' }}
                    {{ ed.title ? ed.title + ' - ' : '' }}
                    {{ ed.description ? ed.description : '' }}
                  </li>
                </ul>
              </div>

              <div *ngIf="doctorFromStore.publication">
                <h1 class="mb-0">Pubblicazioni</h1>
                <ul>
                  <li class="mb-0" *ngFor="let pub of doctorFromStore.publication">
                    {{pub.description}} {{pub.period}}
                  </li>
                </ul>
              </div>

              <div *ngIf="doctorFromStore.languages && doctorFromStore.languages.length > 0 && doctorFromStore.languages[0] !== ''">
                <h1 class="mb-0">Lingue parlate</h1>
                <ul>
                  <li class="mb-0" *ngFor="let lang of doctorFromStore.languages">
                    {{lang}}
                  </li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
