<div class="d-flex align-items-center mt-5 mb-5 topper-info-doctor">
  <div class="img-profile-container large bolder-border">
    <img
      src="./assets/images/profile_doctors/1.png"
      class="img-profile"
      alt=""
    />
  </div>
  <div class="ml-3 pr-3 border-right">
    <h1 class="do3-blue-text do3-fw600 mb-0">Dr. Ernesto Liguori</h1>
    <h3 class="do3-gray-text do3-fw600 mb-0">ID APPUNTAMENTO: 65498</h3>
  </div>
  <h2 class="do3-gray-text do3-fw600 mb-0 ml-3 pr-3 fst-italic">Cardiologo</h2>
</div>
