import { Component, OnDestroy, OnInit } from '@angular/core';
import * as fromApp from '../../../store/app.reducer';
import * as RichiesteActions from '../../richieste/store/richieste.actions';
import { RichiestaDatum, Meta } from 'src/app/model/Richiesta';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-cronologia',
  templateUrl: './cronologia.component.html',
  styleUrls: ['./cronologia.component.scss'],
})
export class CronologiaComponent implements OnInit, OnDestroy {
  private storeSub: Subscription;
  public richieste: RichiestaDatum[];
  public richiesteMeta: Meta | null;
  public hasCronologia: boolean = false;
  public loading: boolean = true;
  public page: number = 0;
  private openPage: number = 0;

  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private location: Location) {}

  ngOnInit(): void {
    this.store.dispatch(
      RichiesteActions.fetchAllRichieste({ searchRequest: true })
    );
    this.storeSub = this.store.select('richieste').subscribe((richieste) => {
      this.richieste = richieste.allRichieste;
      this.richiesteMeta = richieste.allRichiesteMeta;
      this.hasCronologia = this.richieste.length > 0 === true;
      this.loading = richieste.cronologiaLoading;
    });
  }

  onRichiestaClick(richiesta: RichiestaDatum) {
    this.router.navigate(['/medic', richiesta.doctor.id]);
  }

  getDoctorAvatar(richiesta: RichiestaDatum) {
    return `${environment.NEW_API}/doctors/${richiesta.doctor.id}/image`;
  }

  handlePageClick(page: any) {
    this.page = page;
    console.log(this.page);
    this.changePageOpen(this.page);
  }

  ngOnDestroy(): void {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
  }

  getHumanDate = (date: any, lastYear = false, yesterday = false) => {
    let dd: any = new Date();
    let mm = String(date.getMonth() + 1).padStart(2, '0');
    if (yesterday && dd.getDate() === 1) {
      dd.setDate(dd.getDate() - 1);
      mm = String(dd.getMonth() + 1).padStart(2, '0');
      dd = String(dd.getDate()).padStart(2, '0');
    } else if (yesterday) {
      dd.setDate(dd.getDate() - 1);
      dd = String(dd.getDate()).padStart(2, '0');
    } else {
      dd.setDate(dd.getDate());
      dd = String(dd.getDate()).padStart(2, '0');
    }
    const yyyy = lastYear ? date.getFullYear() - 1 : date.getFullYear();
    const humanDate = yyyy + '-' + mm + '-' + dd;
    return humanDate;
  };

  changePageOpen(page: any) {
    this.openPage = page;
    console.log('page open', this.openPage);
    this.store.dispatch(
      RichiesteActions.fetchAllRichieste({
        searchRequest: true,
        // statusValue: 'CLOSED',
        page: this.openPage,
      })
    );
  }

  goBack() {
    this.location.back();
  }
}
