<div class="container consenso">
  <div class="row">
    <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>
  </div>



  <div class="col d-flex align-items-center">
    <button class="do3-back-btn col-12 p-0" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      Torna indietro
    </button>
  </div>
  <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
  <div [hidden]="isLoading">


    <h1 class="do3-fw800 do3-blue-text mt-5 mb-0">
      Compila i campi obbligatori per aggiornare il tuo profilo.
    </h1>

    <app-dati-profilo (isConsenso)="true" (loadingFinished)="loadingFinished()"></app-dati-profilo>

    <h1 class="do3-fw800 do3-blue-text mt-5 mb-0">
      Per accedere al Videoconsulto abbiamo bisogno del tuo consenso.
    </h1>

    <app-consenso-informato></app-consenso-informato>
    <!-- toggle: -->
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center mb-4 mt-4">
          <div class="p-privacy">
            Ho letto e compreso le condizioni del consenso informato.
          </div>
          <div class="">
            <input [(ngModel)]="showOTP" type="checkbox" value="" ngModel class="" name="defaultCheck1"
              id="defaultCheck1" (ngOnclick)="toggleConsenso($event)" (ngModelChange)="toggleConsenso($event)"
              required />
          </div>
          <!-- <div class="toggle-button-cover col-4">
            <div class="button-cover">
              <div class="button r" id="button-1">
                <input
                  [(ngModel)]="showOTP"
                  type="checkbox"
                  value=""
                  ngModel
                  class="checkbox"
                  name="defaultCheck1"
                  id="defaultCheck1"
                  (ngOnclick)="toggleConsenso($event)"
                  (ngModelChange)="toggleConsenso($event)"
                  required
                />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div> -->
          <div *ngIf="weWantOtp">
            <small style="color: #172d55; font-size: 14px; font-weight: 800">Per visualizzare il codice di
              autenticazione vai nella tua
              casella di posta.</small>
          </div>
        </div>

        <div class="row">
          <div class="col-3 d-flex">
            <!-- Inserisci otp -->
            <div class="col-9 d-flex align-items-center">
              <div class="form-group">
                <!-- Error message -->
                <p class="alert alert-danger" *ngIf="error">
                  <span class="fw-bold">{{ errorTitle }}</span> <br />
                  {{ errorMessage }}
                </p>
                <!-- <label for="exampleInputEmail1">Inserisci il codice ottento via email</label> -->
                <input *ngIf="weWantOtp" class="form-control" [(ngModel)]="otpCode" type="text" id="otp"
                  aria-describedby="otp" placeholder="Inserisci il codice" [disabled]="!showOTP" />
                <button class="ms-auto do3-primary-btn mt-3 do3-fw600"
                  [disabled]="!showOTP || completionPercentage != 100" (click)="acceptInformedConsent()">
                  COMPLETA E INVIA
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>