import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { SurveyComponent } from './survey/survey.component';
import { HmComponent } from './components/hm/hm.component';
import { ProssimoConsultoComponent } from './components/prossimo-consulto/prossimo-consulto.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PercorsiComponent } from './components/percorsi/percorsi.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { IdPercorsoComponent } from './percorsi/id-percorso/id-percorso.component';

@NgModule({
  declarations: [
    DashboardComponent,
    HmComponent,
    ProssimoConsultoComponent,
    SurveyComponent,
    PercorsiComponent,
    IdPercorsoComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatIconModule,
    RouterModule.forChild([{ path: ':id', component: IdPercorsoComponent }]),
  ],
  exports: [DashboardComponent],
})
export class DashboardModule {}
