import { createAction, props } from '@ngrx/store';

export const fetchConsent = createAction(
  '[Consent] Fetch Consent',
  props<{ id: string }>()
);

export const fetchConsentSuccess = createAction(
  '[Consent] Fetch Consent Success',
  props<{ consent: any }>()
);

export const fetchConsentFail = createAction(
  '[Consent] Fetch Consent Fail',
  props<{ error: any }>()
);

export const fetchOTP = createAction(
  '[Consent] Fetch OTP',
  props<{ id: string }>()
);

export const fetchOTPSuccess = createAction(
  '[Consent] Fetch OTP Success',
  props<{ otp: any }>()
);

export const fetchOTPFail = createAction(
  '[Consent] Fetch OTP Fail',
  props<{ error: any }>()
);

export const patchConsent = createAction(
  '[Consent] Patch Consent',
  props<{ accepted: boolean; otp: string }>()
);

export const acceptConsent = createAction(
  '[Consent] Accept Consent',
  props<{ id: string; accepted: boolean; otp: string }>()
);

export const acceptConsentSuccess = createAction(
  '[Consent] Accept Consent Success',
  props<{ data: any }>()
);

export const acceptConsentFail = createAction(
  '[Consent] Accept Consent Fail',
  props<{ error: any }>()
);
