import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MedicProfileComponent } from './medic-profile.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [MedicProfileComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([{ path: ':id', component: MedicProfileComponent }]),
    MatIconModule,
  ],
})
export class MedicProfileModule {}
