import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import { fetchProducts } from '../../store/dashboard.actions';
import { Subscription } from 'rxjs';
import { Products } from 'src/app/model/Products';

@Component({
  selector: 'app-percorsi',
  templateUrl: './percorsi.component.html',
  styleUrls: ['./percorsi.component.scss'],
})
export class PercorsiComponent implements OnInit, OnDestroy {
  private dashboardSubscribtion: Subscription;
  public products: Products | null;
  public loading: boolean = false;

  constructor(private store: Store<fromApp.AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(fetchProducts());
    this.dashboardSubscribtion = this.store
      .select('dashboard')
      .subscribe((dashboard) => {
        this.products = dashboard.products;
        this.loading = dashboard.loading;
        console.log('products', this.products);
      });
  }

  ngOnDestroy(): void {
    this.dashboardSubscribtion && this.dashboardSubscribtion.unsubscribe();
  }
}
