<div>

  <div class="box-movergy">
    <img *ngIf="movergyValue <= 50" src="../../../../assets/images/movergy/0.png" alt="movergy-counter">
    <img *ngIf="movergyValue > 50 && movergyValue <= 150" src="../../../../assets/images/movergy/100.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 150 && movergyValue <= 250" src="../../../../assets/images/movergy/200.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 250 && movergyValue <= 350" src="../../../../assets/images/movergy/300.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 350 && movergyValue <= 450" src="../../../../assets/images/movergy/400.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 450 && movergyValue <= 550" src="../../../../assets/images/movergy/500.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 550 && movergyValue <= 650" src="../../../../assets/images/movergy/600.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 650 && movergyValue <= 750" src="../../../../assets/images/movergy/700.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 750 && movergyValue <= 850" src="../../../../assets/images/movergy/800.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 850 && movergyValue <= 950" src="../../../../assets/images/movergy/900.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 950 && movergyValue <= 1050" src="../../../../assets/images/movergy/1000.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1050 && movergyValue <= 1150" src="../../../../assets/images/movergy/1100.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1150 && movergyValue <= 1250" src="../../../../assets/images/movergy/1200.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1250 && movergyValue <= 1350" src="../../../../assets/images/movergy/1300.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1350 && movergyValue <= 1450" src="../../../../assets/images/movergy/1400.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1450 && movergyValue <= 1550" src="../../../../assets/images/movergy/1500.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1550 && movergyValue <= 1650" src="../../../../assets/images/movergy/1600.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1650 && movergyValue <= 1750" src="../../../../assets/images/movergy/1700.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1750 && movergyValue <= 1850" src="../../../../assets/images/movergy/1800.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1850 && movergyValue <= 1950" src="../../../../assets/images/movergy/1900.png"
      alt="movergy-counter">
    <img *ngIf="movergyValue > 1950" src="../../../../assets/images/movergy/2000.png" alt="movergy-counter">

    <div class="movergy-text">
      MOVERGY
      <div class="value">
        {{movergyValue}}
      </div>
      <!-- <button data-bs-toggle="modal" data-bs-target="#movergyModal">
        <mat-icon class="info">info</mat-icon>
      </button> -->
      <div class="do3-gray-text">
        moves/day

      </div>
    </div>
  </div>
</div>
