import * as fromAuth from '../auth/store/auth.reducer';
import * as fromMe from '../core/header/store/me.reducer';
import * as fromDocsSumms from '../features/prenotazione/store/reducers/prenotazione.reducer';
import * as fromRichieste from '../features/richieste/store/richieste.reducer';
import * as fromProfile from '../features/profilo/store/profilo.reducer';
import * as fromMedicProfile from '../features/medic-profile/store/medic-profile.reducer';
import * as fromConsent from '../features/consenso/store/consenso.reducer';
import * as fromDashboard from '../features/dashboard/store/dashboard.reducer';
import { ActionReducerMap } from '@ngrx/store';

export interface AppState {
  auth: fromAuth.AuthState;
  me: fromMe.State;
  prenotazione: fromDocsSumms.State;
  richieste: fromRichieste.State;
  profile: fromProfile.State;
  medicProfile: fromMedicProfile.State;
  consent: fromConsent.State;
  dashboard: fromDashboard.State;
}

export const appReducers: ActionReducerMap<AppState> = {
  auth: fromAuth.authReducer,
  me: fromMe.meReducer,
  prenotazione: fromDocsSumms.docsSummsReducer,
  richieste: fromRichieste.richiesteReducer,
  profile: fromProfile.profileReducer,
  medicProfile: fromMedicProfile.medicProfileReducer,
  consent: fromConsent.consensoReducer,
  dashboard: fromDashboard.dashboardReducer,
};
