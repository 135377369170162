import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { Policy } from 'src/app/model/Policy';
import * as fromApp from 'src/app/store/app.reducer';
import * as ProfileActions from '../store/profilo.actions';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
})
export class PrivacyComponent implements OnInit, OnDestroy {
  private storeSubs: Subscription;
  public policies: Policy | any = null;
  public loadingPolicies: boolean = false;


  constructor(private store: Store<fromApp.AppState>) { }

  ngOnInit(): void {
    this.store.dispatch(ProfileActions.fetchPoliciesProfile());
    this.storeSubs = this.store.select('profile')
      .subscribe((state) => {
        this.policies = state.policies;
        this.loadingPolicies = state.loadingPolicies
      });
  }

  getPolicyLink(policyId: string, resourceId: string) {
    return `${environment.NEW_API}/policies/${policyId}/content/${resourceId}`;
  }

  ngOnDestroy(): void {
    if (this.storeSubs) {
      this.storeSubs.unsubscribe();
    }
  }

  acceptPrivacy(form: NgForm): void {
    if (!form.valid || form.invalid) {
      return;
    }
    console.log('form: ', form.value);
    const privacy1 = {
      accepted: form.value.privacy1,
      policyId: this.policies.data[0].policyId,
      resourceId: this.policies.data[0].resourceId,
    };
    const privacy2 = {
      accepted: form.value.privacy2,
      policyId: this.policies.data[1].policyId,
      resourceId: this.policies.data[1].resourceId,
    };
    const privacy3 = {
      accepted: form.value.privacy3,
      policyId: this.policies.data[2].policyId,
      resourceId: this.policies.data[2].resourceId,
    };
    const allAccepted: any = {
      items: [privacy1, privacy2, privacy3],
    };
    console.log('payload:', allAccepted);
    this.store.dispatch(
      ProfileActions.postPolicyProfile({
        policy: allAccepted,
      })
    );
  }
}
