import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as DashboardActions from './dashboard.actions';
import { AdditionalDetails, Products, ProductsDetail } from 'src/app/model/Products';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private router: Router
  ) {}

  fetchProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.fetchProducts),
      switchMap(() => {
        return this.http.get<Products>(environment.BFF_PATIENT + '/products');
      }),
      map((products) => {
        if (products) {
          return DashboardActions.fetchProductsSuccess({
            products: products,
          });
        } else {
          return DashboardActions.fetchProductsFailure({
            error: 'error: ',
          });
        }
      })
    )
  );

  // fetchProductDetails$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(DashboardActions.fetchProductDetail),
  //     mergeMap((action) =>
  //       this.http.get<ProductsDetail>(
  //         `${environment.BFF_PATIENT}/products/by-code/${action.productCode}`
  //       ).pipe(
  //         mergeMap((productDetails) => {
  //           // Estrai l'ID dal risultato della prima chiamata
  //           const productId = productDetails.id;

  //           // Esegui la seconda chiamata utilizzando l'ID ottenuto
  //           return this.http.get<AdditionalDetails>(
  //             `${environment.BFF_PATIENT}/products/${productId}/details`
  //           ).pipe(
  //             map((additionalDetails) => {
  //               const combinedDetails = {
  //                 productDetails,
  //                 additionalDetails,
  //               };

  //               return DashboardActions.fetchProductDetailSuccess({
  //                 productDetailsCombined: combinedDetails,
  //               });
  //             }),
  //             catchError((error) => {
  //               return of(DashboardActions.fetchProductDetailFailure({ error }));
  //             })
  //           );
  //         }),
  //         catchError((error) => {
  //           return of(DashboardActions.fetchProductDetailFailure({ error }));
  //         })
  //       )
  //     )
  //   )
  // );

  fetchProductDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.fetchProductDetail),
      switchMap((action) => {
        return this.http.get<ProductsDetail>(
          `${environment.BFF_PATIENT}/products/by-code/${action.productId}`
        );
      }),
      map((details) => {
        if (details) {
          return DashboardActions.fetchProductDetailSuccess({
            productDetails: details,
          });
        } else {
          return DashboardActions.fetchProductDetailFailure({
            error: 'error: ',
          });
        }
      })
    )
  );

  fetchProductDetailsVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardActions.fetchProductDetailVersion),
      switchMap((action) => {
        return this.http.get<AdditionalDetails>(
          `${environment.BFF_PATIENT}/products/${action.versionId}/details`
        );
      }),
      map((details) => {
        if (details) {
          console.log("additionaldetails", details);
          return DashboardActions.fetchProductDetailVersionSuccess({
            additionalDetails: details,
          });
        } else {
          console.log("additionaldetails Error");
          return DashboardActions.fetchProductDetailVersionFailure({
            error: 'error: ',
          });
        }
      })
    )
  );

  orderCheckout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DashboardActions.productCheckout),
      switchMap((action) => {
        let body: any = {
          successUrl: action.successUrl,
          cancelUrl: action.cancelUrl,
        };
        if (action.couponCode) {
          body.couponCode = action.couponCode;
        }
        return this.http.post<any>(
          environment.BFF_PATIENT + '/products/' + action.productId + '/checkout',
          body
        ).pipe(
          map((data) => {
            return DashboardActions.productCheckoutSuccess({
              checkOutUrl: data,
            });
          }),
          catchError((error) => {
            return of(DashboardActions.productCheckoutFail({
              error: error,
              productId: action.productId 
            }));
          })
        );
      })
    );
  });

  orderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.productCheckoutSuccess),
        tap((action) => {
          console.log('CheckOutUrl:', action.checkOutUrl.checkOutUrl);
          window.location.href = action.checkOutUrl.checkOutUrl;
        })
      ),
    { dispatch: false }
  );

  orderFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DashboardActions.productCheckoutFail),
        tap((action) => {
          if (action.error.status === 401) {
            // Redirect alla pagina di login con l'URL corrente come parametro
            const currentUrl = this.router.url;
            this.router.navigate(['/login'], { queryParams: { redirectTo: currentUrl, productId: action.productId } });
          } else {
            console.error('Order checkout failed:', action.error);
          }
        })
      ),
    { dispatch: false }
  );
}
