import { Action, createReducer, on } from '@ngrx/store';
import * as ConsensoActions from './consenso.actions';

export interface State {
  contentLoading: boolean;
  otpLoading: boolean;
  error: boolean;
  errorMessage: any;
  content: any;
  otp: any;
}

const initialState: State = {
  contentLoading: false,
  otpLoading: false,
  error: false,
  errorMessage: null,
  content: null,
  otp: null,
};

const _consensoReducer = createReducer(
  initialState,

  on(ConsensoActions.fetchConsent, (state) => ({
    ...state,
    contentLoading: true,
    error: false,
    errorMessage: null,
  })),

  on(ConsensoActions.fetchConsentSuccess, (state, action) => ({
    ...state,
    content: action.consent,
    contentLoading: false,
    error: false,
    errorMessage: null,
  })),

  on(ConsensoActions.fetchConsentFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    contentLoading: false,
  })),

  on(ConsensoActions.fetchOTP, (state) => ({
    ...state,
    otpLoading: true,
    error: false,
    errorMessage: null,
  })),

  on(ConsensoActions.fetchOTPSuccess, (state, action) => ({
    ...state,
    otp: action.otp,
    otpLoading: false,
  })),

  on(ConsensoActions.fetchOTPFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    otpLoading: false,
  })),

  on(ConsensoActions.acceptConsentFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
  }))
);

export const consensoReducer = (state: State | undefined, action: Action) => {
  return _consensoReducer(state, action);
};
