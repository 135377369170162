import { createAction, props } from '@ngrx/store';
import { MeData } from 'src/app/model/Me';
import { Policy } from 'src/app/model/Policy';
import { ProfiloDocumentDatum } from 'src/app/model/ProfiloDocument';

export const fetchDocuments = createAction('[Documents] Fetch Documents');

export const fetchDocumentsSuccess = createAction(
  '[Documents] Fetch Documents Success',
  props<{ documents: ProfiloDocumentDatum[] }>()
);

export const fetchDocumentsFail = createAction(
  '[Documents] Fetch Documents Fail',
  props<{ error: any }>()
);

export const uploadDocument = createAction(
  '[Documents] Upload Document',
  props<{ document: any }>()
);

// qui
// export const fetchImageProfile = createAction('[Profilo] Fetch Image Profile');

// export const fetchImageProfileSuccess = createAction(
//   '[Profilo] Fetch Image Profile Success',
//   props<{ image: any }>()
// );

// export const fetchImageProfileFail = createAction(
//   '[Profile] Fetch Image Profile Fail',
//   props<{ error: any }>()
// );

// export const uploadImageProfile = createAction(
//   '[Profile] Upload Image Profile',
//   props<{ image: any }>()
// );

//qui
export const fetchPoliciesProfile = createAction('[Policies] Fetch Policies');

export const fetchPoliciesSuccess = createAction(
  '[Policies] Fetch Policies Success',
  props<{ policies: Policy }>()
);

export const fetchPoliciesFail = createAction(
  '[Policies] Fetch Policies Fail',
  props<{ error: any }>()
);

export const postPolicyProfile = createAction(
  '[Policies] Post Policy Profile',
  props<{ policy: any }>()
);

export const getImageProfile = createAction(
  '[profilee] Image profile',
  props<{ imageProfile: string }>()
);

export const postProfileInfos = createAction(
  '[Profile] Post Profile Infos',
  props<{
    name: string;
    lastname: string;
    coupon?: string | null;
    phonenumber?: string | null;
  }>()
);

export const postProfileSuccess = createAction(
  '[Profile] Post Profile Success'
);

export const postProfileFail = createAction(
  '[Profile] Post Profile Fail',
  props<{ error: any }>()
);

export const allCoupons = createAction('[Profile] All Coupons');

export const allCouponsSuccess = createAction(
  '[Profile] All Coupons Success',
  props<{ coupons: any }>()
);

export const allCouponsFail = createAction(
  '[Profile] All Coupons Fail',
  props<{ error: any }>()
);

export const resetProfile = createAction('[Profile] Reset Profile');
