import { Action, createReducer, on } from '@ngrx/store';
import { Policy } from 'src/app/model/Policy';
import * as ProfileActions from './profilo.actions';
import { CouponDatum } from 'src/app/model/Coupon';

export interface State {
  documents: any[];
  loading: boolean;
  error: boolean;
  errorMessage: any;
  policies: Policy | null;
  loadingPolicies: boolean;
  imageProfile: string | null;
  coupons: CouponDatum[] | null;
}

const initialState: State = {
  documents: [],
  loading: false,
  error: false,
  errorMessage: null,
  policies: null,
  loadingPolicies: false,
  imageProfile: null,
  coupons: null,
};

const _profileReducer = createReducer(
  initialState,

  on(ProfileActions.fetchDocuments, (state) => ({
    ...state,
    loading: true,
  })),

  on(ProfileActions.fetchDocumentsSuccess, (state, action) => ({
    ...state,
    documents: action.documents,
    loading: false,
  })),

  on(ProfileActions.fetchDocumentsFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    loading: false,
  })),

  on(ProfileActions.fetchPoliciesSuccess, (state, action) => ({
    ...state,
    policies: action.policies,
    loadingPolicies: true,
  })),

  on(ProfileActions.getImageProfile, (state, action) => ({
    ...state,
    imageProfile: action.imageProfile,
  })),

  on(ProfileActions.allCouponsSuccess, (state, action) => ({
    ...state,
    coupons: action.coupons,
  })),

  on(ProfileActions.resetProfile, (state) => ({
    ...state,
    documents: [],
    loading: false,
    error: false,
    errorMessage: null,
    policies: null,
    imageProfile: null,
    coupons: null,
  })),

  on(ProfileActions.postProfileInfos, (state) => ({
    ...state,
    loading: true,
    error: false,
    errorMessage: null,
  })),

  on(ProfileActions.postProfileFail, (state, action) => ({
    ...state,
    error: true,
    errorMessage: action.error,
    loading: false,
  })),

  on(ProfileActions.postProfileSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
  }))
);

export function profileReducer(state: State | undefined, action: Action) {
  return _profileReducer(state, action);
}
