import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit {
  @Input() label: string = 'Digita qui';
  @Input() value: string = '';
  @Input() type: string = 'text';

  constructor() {}

  ngOnInit(): void {}
}
