import { Action, createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';
import { AdditionalDetails, Products, ProductsDetail } from 'src/app/model/Products';

export interface State {
  loading: boolean;
  error: boolean;
  errorMessage: any;
  products: Products | null;
  productDetails: ProductsDetail | null;
  additionalDetails: AdditionalDetails | null;
}

const initialState: State = {
  loading: false,
  error: false,
  errorMessage: null,
  products: null,
  productDetails: null,
  additionalDetails: null
};

const _dashboardReducer = createReducer(
  initialState,

  on(DashboardActions.fetchProducts, (state) => ({
    ...state,
    loading: true,
    error: false,
    errorMessage: null,
    products: null,
  })),

  on(DashboardActions.fetchProductsSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
    products: action.products,
  })),

  on(DashboardActions.fetchProductsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
    products: null,
  })),

  on(DashboardActions.fetchProductDetail, (state) => ({
    ...state,
    loading: true,
    error: false,
    errorMessage: null,
    productDetails: null,
  })),

  // on(DashboardActions.fetchProductDetailSuccess, (state, action) => ({
  //   ...state,
  //   loading: false,
  //   error: false,
  //   errorMessage: null,
  //   productDetails: action.productDetailsCombined.productDetails,
  //   additionalDetails: action.productDetailsCombined.additionalDetails,
  // })),

  on(DashboardActions.fetchProductDetailSuccess, (state, action) => ({
      ...state,
      loading: false,
      error: false,
      errorMessage: null,
      productDetails: action.productDetails,
    })),

  on(DashboardActions.fetchProductDetailFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
    productDetails: null,
  })),

  on(DashboardActions.fetchProductDetailVersion, (state) => ({
    ...state,
    loading: true,
    error: false,
    errorMessage: null,
    additionalDetails: null,
  })),

  on(DashboardActions.fetchProductDetailVersionSuccess, (state, action) => ({
    ...state,
    loading: false,
    error: false,
    errorMessage: null,
    additionalDetails: action.additionalDetails,
  })),

  on(DashboardActions.fetchProductDetailVersionFailure, (state, action) => ({
    ...state,
    loading: false,
    error: true,
    errorMessage: action.error,
    additionalDetails: null,
  }))
);

export function dashboardReducer(state: State | undefined, action: Action) {
  return _dashboardReducer(state, action);
}
