<div class="row do3-consenso-text">
  <div class="col">
    <p class="mb-4">Con il presente modulo, il/la sottoscritto/a:</p>
    <div class="m-2">
      <ul>
        <li>
          <strong>Acconsente</strong> alla esecuzione della prestazione
          sanitaria da remoto ovvero attraverso l’utilizzo di strumenti digitali
          e con le garanzie espresse nella presente informativa;
        </li>
        <li>
          <strong>Dichiara</strong> di essere stato/a informato/a di quanto
          segue: il medico di riferimento che referterà i dati non sarà presente
          fisicamente nella stessa stanza con il/la sottoscritto/a. I medici o
          il personale infermieristico e i tecnici potranno assistere all’esame
          per ogni fase necessaria alla buona riuscita;
        </li>
        <li>
          <strong>Dichiara</strong> di essere stato/a adeguatamente informato/a
          in modo chiaro e comprensibile sulla metodica della prestazione
          sanitaria, adottata e acconsente alla registrazione di ogni dato
          clinico, biologico, audio e video necessario ai fini dell’obiettivo da
          raggiungere;
        </li>
        <li>
          <strong>Acconsente</strong> che i dati raccolti clinici, biologici,
          audio e video necessari entreranno a far parte della cartella
          clinica/Dossier sanitario del medico specialista di riferimento, e che
          gli stessi possono essere utilizzati solo a scopo di documentazione o
          di assistenza sanitaria da parte di tutti coloro che sono coinvolti
          nel passaggio di informazioni alfine di eseguire la prestazione
          richiesta e per ogni altro aspetto organizzativo ed amministrativo ma
          nei soli limiti richiesti in ragione della finalità di raccolta e
          trattamento;
        </li>
        <li>
          <strong>Dichiara</strong> di essere stato/a informato/a dei vantaggi e
          dei rischi relativi alla visita in oggetto, sia quelli correlati allo
          specifico ricorso a strumenti digitali ed alla distanza fisica tra
          medico e paziente o all’impossibilità di un controllo di una
          sintomatologia non in presenza, sia dell’impossibilità di una visita
          completa e di un intervento immediato in caso di urgenza;
        </li>
        <li>
          <strong>Dichiara</strong> di essere stato/a informato/a del fatto che
          esistono metodi alternativi per eseguire la visita ed in particolare
          che è possibile rivolgersi a strutture del servizio sanitario
          nazionale, aziende convenzionate con il servizio sanitario e/o aziende
          e laboratori privati che erogano la prestazione con metodi
          tradizionali;
        </li>
        <li>
          <strong>Dichiara</strong> di essere stato/a informato/a che nel caso
          di mancato raggiungimento degli obiettivi relativi alla prestazione
          sanitaria in oggetto dovuti a motivi tecnici (black out, problemi di
          connessione ) ed altre ragioni, la stessa prestazione medica verrà
          riprogrammata;
        </li>
        <li>
          <strong>Dichiara</strong> di essere stato/a informato/a che in ogni
          caso la responsabilità dell’esame dipenderà dal medico specialista e
          dall’eventuale struttura sanitaria dalla quale questo dipende;
        </li>
        <li>
          <strong>Autorizza</strong> il medico incaricato ad utilizzare i dati
          sanitari, ma solo in forma anonima ed aggregata, al fine di studio
          scientifico, controllo economico delle prestazioni, elaborazioni di
          dati epidemiologici e pubblicazioni scientifiche o congressuali ed in
          ogni caso, nel rispetto delle normative vigenti in materia;
        </li>
        <li>
          <strong>Autorizza</strong>, inoltre, alla trasmissione dei dati, delle
          fatture e dei referti, se richiesto, alle compagnie di assicurazione
          che copriranno i costi delle proprie prestazioni e che verranno
          indicate esplicitamente;
        </li>
        <li>
          <strong>Dichiara</strong> che la procedura indicata è stata
          correttamente spiegata ed ha ottenuto risposta alle domande che sono
          state poste;
        </li>
        <li>
          <strong>Dichiara</strong> di essere stato/a informato/a che in
          qualsiasi momento può revocare il proprio consenso alla prestazione
          sanitaria salvo che l’atto non sia più arrestabile;
        </li>
        <li>
          <strong>Autorizza</strong> che tutte le informazioni riguardanti il
          proprio stato di salute siano portate a canoscenza di un terzo
          delegato o di eventuali terze persone.
        </li>
      </ul>
      <p>
        Visto e compreso perfettamente tutte le informazioni fornite con la
        presente informativa sulla prestazione sanitaria da remoto e sulle
        modalità di trattamento e conservazione dei dati, il/la sottoscritto/a
        fornisce il consenso.
      </p>
    </div>
  </div>
  <!-- <div class="row">
    <p>Ho letto e compreso le condizioni del consenso informato del dottore {{doctor}}</p>
  </div> -->
</div>
