import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercorsiComponent } from './percorsi.component';
import { PercorsiRoutingModule } from './percorsi-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { IdPercorsoComponent } from '../dashboard/percorsi/id-percorso/id-percorso.component';

@NgModule({
  declarations: [
    PercorsiComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([{ path: ':id', component: IdPercorsoComponent }]),
  ],
  exports: [PercorsiComponent, PercorsiRoutingModule],
})
export class PercorsiModule { }
