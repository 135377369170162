import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ChatClientService,
  ChannelService,
  StreamI18nService,
} from 'stream-chat-angular';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as fromApp from 'src/app/store/app.reducer';
import { fetchUserInfo } from 'src/app/core/header/store/me.actions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import jwtDecode from 'jwt-decode';

@Component({
  selector: 'app-get-stream',
  templateUrl: './get-stream.component.html',
  styleUrls: ['./get-stream.component.scss'],
})
export class GetStreamComponent implements OnInit, OnDestroy {
  private meSub: Subscription;
  private avatarUrl: any;
  private chatToken: string | null = '';
  private chatUserToken: string = '';
  private chatDisplayName: string = '';
  public loading: boolean = false;

  constructor(
    private chatService: ChatClientService,
    private channelService: ChannelService,
    private streamI18nService: StreamI18nService,
    private store: Store<fromApp.AppState>,
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) {
    this.streamI18nService.setTranslation();
  }

  async getToken() {
    const user = await this.afAuth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      this.chatUserToken = token;
      return token;
    } else {
      this.chatToken = null;
      console.log('No user found');
      return null;
    }
  }

  async ngOnInit() {
    this.getAvatar();
    this.store.dispatch(fetchUserInfo());
    this.getToken();
    this.meSub = this.store.select('me').subscribe(async (me) => {
      this.chatDisplayName = me.userInfo?.displayName || '';
      this.chatToken = me.userInfo?.chatInfo.userToken || '';

      if (this.chatToken && this.chatUserToken) {
        const decodedId: any = jwtDecode(this.chatToken);

        this.chatService.init(
          environment.streamChat.apiKey,
          decodedId.user_id,
          this.chatToken
        );

        this.channelService.init({
          type: 'messaging',
          members: { $in: [decodedId.user_id] },
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.meSub && this.meSub.unsubscribe();
  }

  getAvatar() {
    // return `${environment.NEW_API}/profile/image`;
    return this.http
      .get(`${environment.NEW_API}/profile/image`, { responseType: 'blob' })
      .subscribe((data: any) => {
        return this.createImageFromBlob(data);
      });
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.avatarUrl = reader.result;
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }
}
