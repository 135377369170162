import { createAction, props } from '@ngrx/store';
import { AvailableDate, SlotDate } from 'src/app/model/Calendar';
import { DoctorData, DoctorSummary } from 'src/app/model/DoctorSummary';
import { Policy } from 'src/app/model/Policy';
import { PolicyPayload } from 'src/app/model/PolicyPayload';
import { PrenotazioneResData } from 'src/app/model/PrenotazioneResData';

export const setDocsSumms = createAction(
  '[Prenotazione] Set Docs Summs',
  props<{
    docSumms: DoctorSummary;
  }>()
);

export const setSelectDoctor = createAction(
  '[Prenotazione] Set Select Doctor',
  props<{
    selectedDoctor: DoctorData;
  }>()
);

export const setAllAvailabilities = createAction(
  '[Prenotazione] Set Selected Appointment',
  props<{
    allAvailabilities: AvailableDate[];
  }>()
);

export const setAllSlots = createAction(
  '[Prenotazione] Set All Slots',
  props<{
    slotsAvailable: SlotDate[];
  }>()
);

export const setSelectedAppointment = createAction(
  '[Prenotazione] Set Selected Appointment',
  props<{
    selectedAppointment: SlotDate;
  }>()
);

export const prenotazioneResData = createAction(
  '[Prenotazione] Prenotazione Res Data',
  props<{
    data: PrenotazioneResData;
  }>()
);

export const patchPrenotazioneResData = createAction(
  '[Prenotazione] Patch Prenotazione Res Data',
  props<{
    data: PrenotazioneResData;
  }>()
);

export const resetPrenotazioni = createAction(
  '[Prenotazione] Reset Prenotazioni'
);

export const fetchAreas = createAction('[Prenotazione] Fetch Areas');

export const setAreas = createAction(
  '[Prenotazione] Set Areas',
  props<{
    areas: any;
  }>()
);

export const fetchDocsSumms = createAction(
  '[Prenotazione] Fetch Docs Summs',
  props<{
    areaId?: string;
  }>()
);

export const filterDocs = createAction(
  '[Prenotazione] Filter Docs',
  props<{
    textSearch: string;
  }>()
);

export const fetchAvailableAppointments = createAction(
  '[Prenotazione] Fetch Available Appointments',
  props<{
    doctorId: string;
  }>()
);

export const fetchAllSlots = createAction(
  '[Prenotazione] Fetch All Slots',
  props<{
    doctorId: string;
    dateId: string;
  }>()
);

export const fetchPolicies = createAction(
  '[Prenotazione] Fetch Policies',
  props<{
    options: boolean;
  }>()
);

export const fetchPoliciesSuccess = createAction(
  '[Prenotazione] Fetch Policies Success',
  props<{
    policies: Policy;
  }>()
);

export const fetchPoliciesFail = createAction(
  '[Prenotazione] Fetch Policies Fail',
  props<{
    error: any;
  }>()
);

export const postPolicy = createAction(
  '[Policies] Post Policy',
  props<{ policy: PolicyPayload }>()
);

export const postPolicySuccess = createAction('[Policies] Post Policy Success');

export const postPolicyFail = createAction(
  '[Policies] Post Policy Fail',
  props<{ error: any }>()
);

export const postPrenotazione = createAction(
  '[Prenotazione] Post Prenotazione',
  props<{
    selectedDoctor: string;
    medicalSpecializationId: string;
  }>()
);

export const patchPrenotazione = createAction(
  '[Prenotazione] Patch Prenotazione',
  props<{
    sessionId: string;
    doctorSlotId: string;
    date?: string;
  }>()
);

export const confirmPrenotazione = createAction(
  '[Prenotazione] Confirm Prenotazione',
  props<{
    sessionId: string;
  }>()
);

export const stripeCheckout = createAction(
  '[Prenotazione] Stripe Checkout',
  props<{
    sessionId: string;
    successUrl: string;
    cancelUrl: string;
    couponCode?: string;
  }>()
);

export const stripeCheckoutSuccess = createAction(
  '[Prenotazione] Stripe Checkout Success',
  props<{
    checkOutUrl: any;
  }>()
);

export const stripeCheckoutFail = createAction(
  '[Prenotazione] Stripe Checkout Fail',
  props<{
    error: any;
  }>()
);

export const prenotazioneSuccess = createAction(
  '[Prenotazione] Prenotazione Success'
);
