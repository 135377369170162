import { NgModule } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { WorkInProgressComponent } from './shared/work-in-progress/work-in-progress.component';
import { SessionGuard } from './session.guard';
import { SuccessoPrenotazioneComponent } from './features/prenotazione/successo-prenotazione/successo-prenotazione.component';
import { CronologiaComponent } from './features/profilo/cronologia/cronologia.component';
import { AnamnesiComponent } from './features/profilo/anamnesi/anamnesi.component';
import { InfoProfiloComponent } from './features/profilo/info-profilo/info-profilo.component';
import { FatturazioneComponent } from './features/profilo/fatturazione/fatturazione.component';
import { ContattiComponent } from './features/contatti/contatti.component';
import { PercorsiComponent } from './features/percorsi/percorsi.component';
import { ReedemCouponsComponent } from './features/reedem-coupons/reedem-coupons.component';
import { VerifyEmailComponent } from './features/verify-email/verify-email.component';

//const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const redirectUnauthorizedToLogin = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  let redirectUrl = state.url;
  return redirectUnauthorizedTo(['/login', { redirectTo: redirectUrl }]);
};

const redirectLoggedInToRichieste = () =>
  redirectLoggedInTo(['riscatta-coupons']);

const routes: Routes = [
  {
    path: 'work-in-progress',
    component: WorkInProgressComponent,
  },
  {
    path: '**/success',
    redirectTo: '/success',
  },
  {
    path: 'percorsi/success',
    canActivate: [SessionGuard],
    component: SuccessoPrenotazioneComponent,
  },
  {
    path: 'questionari',
    component: AnamnesiComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'cronologia',
    component: CronologiaComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'profilo',
    component: InfoProfiloComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'pagamenti',
    component: FatturazioneComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'percorsi',
    component: PercorsiComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'anamnesi',
    loadChildren: () =>
      import('./features/anamnesi/anamnesi.module').then(
        (m) => m.AnamnesiModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/features/login/login.module').then((m) => m.LoginModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToRichieste },
  },
  {
    path: 'prenotazione',
    loadChildren: () =>
      import('./features/prenotazione/prenotazione.module').then(
        (m) => m.PrenotazioneModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'richiesta',
    loadChildren: () =>
      import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'consenso',
    loadChildren: () =>
      import('./features/consenso/consenso.module').then(
        (m) => m.ConsensoModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  // {
  //   path: 'richieste',
  //   loadChildren: () =>
  //     import('./features/richieste/richieste.module').then(
  //       (m) => m.RichiesteModule
  //     ),
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectUnauthorizedToLogin },
  // },
  {
    path: 'profilo',
    loadChildren: () =>
      import('./features/profilo/profilo.module').then((m) => m.ProfiloModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'riscatta-coupons',
    loadChildren: () =>
      import('./features/reedem-coupons/reedem-coupons.module').then(
        (m) => m.ReedemCouponsModule
      ),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'verifica-email',
    component: VerifyEmailComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  // {
  //   path: 'home',
  //   loadChildren: () =>
  //     import('./features/profilo/profilo.module').then((m) => m.ProfiloModule),
  //   canActivate: [AngularFireAuthGuard],
  //   data: { authGuardPipe: redirectUnauthorizedToLogin },
  // },
  {
    path: 'percorsi',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    // canActivate: [AngularFireAuthGuard],
    // data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'membership',
    loadChildren: () =>
      import('./features/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    //canActivate: [AngularFireAuthGuard],
    //data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'assistenza',
    loadChildren: () =>
      import('./features/assistenza/assistenza.module').then(
        (m) => m.AssistenzaModule
      ),
  },
  {
    path: 'medic',
    loadChildren: () =>
      import('./features/medic-profile/medic-profile.module').then(
        (m) => m.MedicProfileModule
      ),
  },
  {
    path: 'signup',
    loadChildren: () =>
      import('./auth/features/signup/signup.module').then(
        (m) => m.SignupModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/features/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: 'loading',
    loadChildren: () =>
      import('./features/logout/logout.module').then((m) => m.LogoutModule),
  },
  {
    path: 'cookiepolicy',
    loadChildren: () =>
      import('./features/cookie-policy/cookie-policy.module').then(
        (m) => m.CookiePolicyModule
      ),
  },
  {
    path: 'terminiecondizioni',
    loadChildren: () =>
      import('./features/termini-condizioni/termini-condizioni.module').then(
        (m) => m.TerminiCondizioniModule
      ),
  },
  {
    path: 'privacypolicy',
    loadChildren: () =>
      import('./features/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'policy',
    loadChildren: () =>
      import(
        './features/prenotazione/trattamento-dati/trattamento-dati.module'
      ).then((m) => m.TrattamentoDatiModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'contatti',
    component: ContattiComponent,
  },
  {
    path: 'prenotazione/success',
    loadChildren: () =>
      import(
        './features/prenotazione/successo-prenotazione/successo-prenotazione.module'
      ).then((m) => m.SuccessoPrenotazioneModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },
  {
    path: 'success',
    loadChildren: () =>
      import(
        './features/prenotazione/successo-prenotazione/successo-prenotazione.module'
      ).then((m) => m.SuccessoPrenotazioneModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
  },

  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
