<app-loading-spinner
  *ngIf="isLoading"
  class="d-flex vh-100 justify-content-center align-items-center"
></app-loading-spinner>

<div
  *ngIf="!emailVerified"
  class="email-verification container-fluid fullscreen-message"
>
  <div>
    <!-- Alert email non verificata -->
    <div
      *ngIf="error"
      class="alert alert-danger alert-dismissible fade show"
      role="alert"
    >
      La tua email non è verificata. Controlla la posta elettronica prima di
      continuare.
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>

    <!-- Success email di verifica inviata -->
    <div
      *ngIf="emailSent"
      class="alert alert-success alert-dismissible fade show"
      role="alert"
    >
      Email inviata! Controlla di nuovo la posta in arrivo e conferma il tuo
      indirizzo.
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <h1 class="text-gradient-drfeel">Verifica la tua email</h1>
  <p>
    Abbiamo inviato un'email di verifica all'indirizzo che hai usato per la
    registrazione.
    <br />
    Controlla la posta e segui le indicazioni per continuare.
  </p>
  <button
    class="btn-continue my-4 do3-secondary-btn lightBlue w-auto d-flex align-items-center cust"
    (click)="handleContinueButton()"
  >
    Continua
  </button>

  <h2>
    Non hai ricevuto l'email di verifica? Controlla nello spam oppure
    <a (click)="resendVerificationEmail()">invia una nuova email di verifica</a
    >.
  </h2>
</div>
