<div class="dash">
  <app-loading-spinner *ngIf="isLoading && !richieste"></app-loading-spinner>

  <span *ngIf="richieste?.data && richieste?.data?.length ?? []">
    <app-prossimo-consulto [richiesta]="nextAppuntamento" (changeStep)="receiveStep($event)"></app-prossimo-consulto>
  </span>

  <app-hm [nomeUtente]="nomeUtente"></app-hm>
  <app-survey></app-survey>
  <app-percorsi></app-percorsi>
  <div class="docs mt-4">
    <div *ngIf="documentsFromStore.length == 0">
      <h1 class="mb-0"><strong>Non hai ancora caricato nessun documento</strong></h1>
      <h2>
        Per caricarne uno, vai alla sezione documenti
      </h2>
      <span class="rosa-link" (click)="goToDoc('diagnosis')">Vai alla sezione documenti</span>
    </div>

    <div *ngIf="documentsFromStore.length > 0">
      <h1 class="mb-0"><strong>Ultimi documenti caricati:</strong></h1>
      <div class="d-flex">
        <div class="doc my-3" (click)="goToDoc('diagnosis')">
          <div class="colore"></div>
          <div class="d-flex align-items-center inner-cont">
            <div>
              <img src="./assets/images/referti.svg" alt="" />
            </div>
            <div class="flex-grow-1">
              <div class="title-doc">Referti</div>
              <span *ngIf="lastReferto">Caricate il
                {{ lastReferto.dateTime | date : "dd/MM/yyyy" }}</span>
              <span *ngIf="!lastReferto">Non ci sono referti disponibili</span>
            </div>
            <mat-icon>arrow_forward_ios</mat-icon>
          </div>
        </div>
        <div class="doc my-3" (click)="goToDoc('prescription')">
          <div class="colore"></div>
          <div class="d-flex align-items-center inner-cont">
            <div>
              <svg id="Raggruppa_21316" data-name="Raggruppa 21316" xmlns="http://www.w3.org/2000/svg" width="18.044"
                height="27" viewBox="0 0 18.044 27">
                <path id="Tracciato_85431" data-name="Tracciato 85431"
                  d="M129.778,7.1a3.73,3.73,0,0,1-3.423-2.734h1.254a1.373,1.373,0,0,0,1.476-1.234v-1.9A1.373,1.373,0,0,0,127.609,0H116.317a1.373,1.373,0,0,0-1.476,1.234v1.9a1.373,1.373,0,0,0,1.476,1.234h1.254A3.73,3.73,0,0,1,114.148,7.1a1.286,1.286,0,0,0-1.207,1.195V25.807A1.327,1.327,0,0,0,114.368,27h15.19a1.327,1.327,0,0,0,1.427-1.193V8.294A1.286,1.286,0,0,0,129.778,7.1Zm-.22,19.11h-15.19a.447.447,0,0,1-.481-.4V23.823h11.051a.4.4,0,1,0,0-.791H113.887V11.116l3.625,0a.4.4,0,1,0,0-.791l-3.625,0V8.294a.446.446,0,0,1,.411-.414c2.294-.308,4-1.755,4.227-3.515h3.048a.44.44,0,0,0,.473-.4.44.44,0,0,0-.473-.4h-5.256a.493.493,0,0,1-.53-.443V2.116h1.9a.44.44,0,0,0,.473-.4.44.44,0,0,0-.473-.4h-1.9V1.234a.493.493,0,0,1,.53-.443h11.292a.493.493,0,0,1,.53.443v1.02h-1.906a.44.44,0,0,0-.473.4.44.44,0,0,0,.473.4h1.906v.086a.493.493,0,0,1-.53.443h-3.82a.44.44,0,0,0-.473.4.44.44,0,0,0,.473.4H125.4c.225,1.761,1.932,3.208,4.226,3.515a.446.446,0,0,1,.411.414v2.031H119.712a.4.4,0,1,0,0,.791h10.327V23.032h-2.889a.4.4,0,1,0,0,.791h2.89v1.984A.447.447,0,0,1,129.558,26.209Z"
                  transform="translate(-112.941)" fill="#2f2f2f" />
                <path id="Tracciato_85432" data-name="Tracciato 85432"
                  d="M146.41,231.423a1.454,1.454,0,0,0,.6-1.113v-2.386a1.928,1.928,0,0,0-2-1.583,1.862,1.862,0,0,0-1.948,1.587v2.386C143.072,231.586,145.08,232.493,146.41,231.423Zm-2.056-4.05a1.037,1.037,0,0,1,1.67.554v.8h-1.982v-.8A.743.743,0,0,1,144.354,227.373Zm-.312,2.937v-.8h1.982v.8a.743.743,0,0,1-.312.55A1.031,1.031,0,0,1,144.042,230.31Zm6.438,1.244a2.409,2.409,0,0,0-2.8,0l-2.1,1.687c-1.108.906-.494,2.688,1.392,2.688a2.255,2.255,0,0,0,1.406-.447l2.1-1.687A1.444,1.444,0,0,0,150.481,231.554Zm-2.8,3.37a1.217,1.217,0,0,1-.706.212c-1.025,0-1.171-.948-.692-1.34l.7-.563,1.4,1.125Zm2.1-1.687-.7.563-1.4-1.125.7-.565a1.149,1.149,0,0,1,.705-.212C150.124,231.9,150.235,232.865,149.781,233.237Zm7.342-3.5c1.476-1.588,1.034-3.127-.1-3.8a3.023,3.023,0,0,0-3.246.175c-2.448-1.772-6,.778-3.347,3.628a10.889,10.889,0,0,0,3.123,1.888.6.6,0,0,0,.449,0A10.889,10.889,0,0,0,157.123,229.741Zm-3.347,1.088a9.169,9.169,0,0,1-2.558-1.561,2.028,2.028,0,0,1-.663-2.008,1.709,1.709,0,0,1,2.8-.344.563.563,0,0,0,.832,0,1.84,1.84,0,0,1,1.137-.565c1.392-.176,2.568,1.234,1,2.917A9.165,9.165,0,0,1,153.776,230.828Z"
                  transform="translate(-141.469 -213.633)" fill="#2f2f2f" />
              </svg>
            </div>
            <div class="flex-grow-1">
              <div class="title-doc">Prescrizioni</div>
              <span *ngIf="lastPrescrizione">Caricate il
                {{ lastPrescrizione.dateTime | date : "dd/MM/yyyy" }}</span>
              <span *ngIf="!lastPrescrizione">Non ci sono prescrizioni attive</span>
            </div>
            <mat-icon>arrow_forward_ios</mat-icon>
          </div>
        </div>
        <div class="doc my-3" (click)="goToDoc('analysis')">
          <div class="colore"></div>
          <div class="d-flex align-items-center inner-cont">
            <div>
              <img src="./assets/images/analisi.svg" alt="" />
            </div>
            <div class="flex-grow-1">
              <div class="title-doc">Esami</div>
              <span *ngIf="lastAnalisi">Caricate il {{ lastAnalisi.dateTime | date : "dd/MM/yyyy" }}</span>
              <span *ngIf="!lastAnalisi">Non hai effettuato esami</span>
            </div>
            <mat-icon>arrow_forward_ios</mat-icon>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- Pacchetto Modal -->
  <div class="modal fade" id="modalPacchetto" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <p>
            Ciao! Hai un pagamento in sospeso.<br />
            Vai alla sezione “Pagamenti” per completare l’operazione e accedere al
            percorso.
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn light-blue text-white text-uppercase" data-bs-dismiss="modal">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>

  <button #modalPacchetto type="button" data-bs-toggle="modal" data-bs-target="#modalPacchetto" [hidden]="true">
    Pagamento
  </button>
</div>