import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import { Policy } from 'src/app/model/Policy';
import { ProfiloDocument } from 'src/app/model/ProfiloDocument';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import * as ProfiloActions from './profilo.actions';
import { Coupon } from 'src/app/model/Coupon';

@Injectable()
export class ProfiloEffects {
  constructor(private actions$: Actions, private http: HttpClient) {}

  fetchDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.fetchDocuments),
      switchMap(() => {
        console.log('fetching profilo');
        return this.http.get<ProfiloDocument>(
          environment.NEW_API + '/documents'
        );
      }),
      map((docs) => {
        if (docs) {
          return ProfiloActions.fetchDocumentsSuccess({
            documents: docs.data,
          });
        } else {
          return ProfiloActions.fetchDocumentsFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  uploadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.uploadDocument),
      switchMap((action) => {
        return this.http.post<any>(
          environment.NEW_API + '/documents',
          action.document
        );
      }),
      map((docs) => {
        if (docs) {
          return ProfiloActions.fetchDocumentsSuccess({
            documents: docs.data,
          });
        } else {
          return ProfiloActions.fetchDocumentsFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  fetchPolicies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.fetchPoliciesProfile),
      switchMap(() => {
        return this.http.get<Policy>(environment.NEW_API + '/policies');
      }),
      map((policies) => {
        if (policies) {
          return ProfiloActions.fetchPoliciesSuccess({
            policies: policies,
          });
        } else {
          return ProfiloActions.fetchPoliciesFail({
            error: 'error: ',
          });
        }
      })
    )
  );

  postPolicy$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.postPolicyProfile),
      switchMap((action) => {
        return this.http.post<any>(
          environment.NEW_API + '/policies/multiple',
          action.policy
        );
      }),
      map((policies) => {
        if (policies) {
          return ProfiloActions.fetchPoliciesSuccess({
            policies: policies,
          });
        } else {
          return ProfiloActions.fetchPoliciesFail({
            error: 'error: unable to fetch policies',
          });
        }
      })
    )
  );

  postProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.postProfileInfos),
      switchMap((action) => {
        let body: any = {};
        if (action.name) {
          body.firstName = action.name;
        }
        if (action.lastname) {
          body.lastName = action.lastname;
        }
        if (action.coupon) {
          body.coupon = action.coupon;
        }
        if (action.phonenumber) {
          body.phoneNumber = action.phonenumber;
        }
        console.log('post profile', body);
        return this.http.post<any>(environment.NEW_API + '/profile', body);
      }),
      map((profile) => {
        console.log('post profile from profile effects');
        if (profile) {
          return ProfiloActions.postProfileSuccess();
        } else {
          return ProfiloActions.postProfileFail({
            error: 'error: unable to post profile',
          });
        }
      }),
      catchError((error) => {
        console.log('error', error);
        return of(
          ProfiloActions.postProfileFail({
            error: 'Codice errato',
          })
        );
      })
    )
  );

  allCoupons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfiloActions.allCoupons),
      switchMap(() => {
        return this.http.get<Coupon>(environment.NEW_API + '/coupons');
      }),
      map((coupons) => {
        if (coupons) {
          return ProfiloActions.allCouponsSuccess({
            coupons: coupons.data,
          });
        } else {
          return ProfiloActions.allCouponsFail({
            error: 'error: ',
          });
        }
      })
    )
  );
}
