import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chatta-hm',
  templateUrl: './chatta-hm.component.html',
  styleUrls: ['./chatta-hm.component.scss'],
})
export class ChattaHmComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
