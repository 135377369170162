import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-assistenza',
  templateUrl: './assistenza.component.html',
  styleUrls: ['./assistenza.component.scss']
})
export class AssistenzaComponent implements OnInit {
  headerTitle: string;
  headerDescription: string;

  accordions: any = [
    {
      funzioni_disponibili: [
        {
          title: "Posso caricare una foto o un’immagine diagnostica?",
          description: "Sì. Se vuoi caricare un’immagine diagnostica o un documento clinico come un referto, una radiografia o una foto vai alla sezione “Documenti”, clicca sull’icona “+” e attendi il caricamento."
        },
        {
          title: "Perché devo compilare il questionario?",
          description: "Per personalizzare il tuo percorso abbiamo esigenza di conoscere nel dettaglio le tue esigenze di salute, compilare il questionario ci aiuterà ad ottenere tutte le informazioni indispensabili per offrirti la migliore esperienza possibile."
        },
        {
          title: "Posso modificare le mie impostazioni di privacy?",
          description: "Sì, ti basta andare alla sezione “Privacy”, in quest’area potrai modificare le tue preferenze per il trattamento dei dati personali in qualunque momento."
        }
      ],
      televisita: [
        {
          title: "Come faccio ad accedere al Videoconsulto?",
          description: "Per accedere al videoconsulto vai alla sezione “Appuntamenti” e clicca sul tasto “collegati”. Prima di richiedere il videoconsulto però, verifica i requisiti minimi di accesso."
        },
        {
          title: "Non riesco ad accedere al Videoconsulto. Perché?",
          description: "Per collegarti in videoconsulto bisogna rispettare i requisiti minimi richiesti, ovvero: essere in possesso di una webcam funzionante, di un microfono attivo e di un browser a scelta tra Firefox e Chrome."
        },
        {
          title: "Il medico non mi vede, perchè?",
          description: "Per comparire in video è indispensabile avere una webcam attiva e correttamente configurata. Sei certo di aver seguito tutti gli step?"
        },
        {
          title: "Il medico non mi sente, perchè?",
          description: "Per avviare una conversazione è importante avere un microfono interno o esterno sempre attivo. Sei certo di aver configurato tutto correttamente?"
        },
        {
          title: "Ho avuto un imprevisto, come posso rimandare l’appuntamento?",
          description: "In caso di problemi con un appuntamento contatta il tuo Health Manager, potrai fissare con lui un nuovo videoconsulto."
        }
      ],
      /*diario_medico: [
        {
          title: "Come faccio a caricare un documento medico?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          title: "Come faccio a scaricare un documento medico?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          title: "Come faccio a scrivere ad un medico?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        },
        {
          title: "Come faccio a consultare la diagnosi?",
          description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
        }
      ],*/
      agenda: [
        {
          title: "Dove posso trovare le televisite in programma?",
          description: "Se vuoi controllare i videoconsulti in programma clicca sul tasto “Appuntamenti”, in quest’area troverai l’elenco di tutti gli appuntamenti fissati dal tuo Health Manager."
        }
      ],
    }
  ]

  constructor() { }

  viewMode: any;
  ngOnInit(): void {
    this.viewMode = "funzioni_disponibili";
    this.headerTitle = "Funzioni Disponibili"
    this.headerDescription = "La navigazione in piattaforma ti crea qualche disagio? Niente paura. Consulta la nostra sezione dedicata per un supporto veloce.";
  }
}
