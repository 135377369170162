<div class="custom-navbar">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <mat-toolbar>
          <div class="menu-button">
            <!-- <button mat-icon-button (click)="onToggleSidenav()">
              <mat-icon class="do3-blue-text">menu</mat-icon>
            </button> -->
          </div>
          <div>
            <!-- <img class="logo-navbar" src="./assets/images/logo-colori.png" alt="dottori online" /> -->
            <img
              class="logo-navbar"
              src="./assets/images/logo-drfeel-colori.png"
              alt="DrFeel logo"
            />
            <!-- <span class="drfeel-gradient">Dr.Feel</span> -->
          </div>
          <div class="justify-content-end w-100 navbar-menu">
            <ul *ngIf="!auth" class="navigation-items">
              <li>
                <a routerLink="/login" routerLinkActive="activeLink">Accedi </a>
              </li>
              <!-- <li>
                <a routerLink="/signup" routerLinkActive="activeLink"
                  >Registrati
                </a>
              </li> -->
              <li>
                <a routerLink="/contatti" routerLinkActive="activeLink"
                  >Contatti</a
                >
              </li>
              <li>
                <a routerLink="/assistenza" routerLinkActive="activeLink"
                  >Centro Assistenza</a
                >
              </li>
            </ul>
            <ul *ngIf="auth" class="navigation-items">
              <!-- <li>
                <a routerLink="/home" routerLinkActive="activeLink">Home</a>
              </li> -->
              <li>
                <a routerLink="/contatti" routerLinkActive="activeLink"
                  >Contatti</a
                >
              </li>
              <li>
                <a routerLink="/assistenza" routerLinkActive="activeLink"
                  >Centro Assistenza</a
                >
              </li>
              <li>
                <a routerLink="" routerLink (click)="onLogout()"
                  >Logout</a
                >
              </li>
              <!-- <li>
                <a routerLink="/contatti" routerLinkActive="activeLink">Contatti</a>
              </li> -->
              <!-- <li>
                <a href="https://drfeel.com/blog/" target="_blank"
                  >Blog</a
                >
              </li> -->
              <!-- qui -->
              <!-- <li class="link-pointer" [matMenuTriggerFor]="language">
                <img src="assets/images/italia.svg" />
                <li>
                    <a href="https://drfeel.com/work-in-progress/">
                      <img src="assets/images/granbretagna.svg" />
                    </a>
                  </li>
              </li>
              <mat-menu #language="matMenu" class="custom-lang">
                <a href="https://drfeel.com/work-in-progress/">
                  <img src="assets/images/granbretagna.svg" />
                  <div>Inglese</div>
                </a>
              </mat-menu> -->
            </ul>
          </div>
        </mat-toolbar>
      </div>
    </div>
  </div>
</div>
