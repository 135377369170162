<div class="container cronologia my-5">
  <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>
  <div class="col d-flex align-items-center">
    <button class="do3-back-btn col-12 p-0" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      Torna indietro
    </button>
  </div>
  <div class="row mt-4">
    <div class="heading_cronologia">
      <h2 class="mb-0">Cronologia</h2>
      <p>Accedi allo storico dei videoconsulti effettuati fino ad oggi</p>
    </div>
    <section class=" m-0">
      <app-loading-spinner *ngIf="loading"></app-loading-spinner>
      <ng-container *ngIf="!loading">
        <div class="d-md-none super-margin">
          <h1 class="do3-lightblue-text do3-fw800">Cronologia</h1>
        </div>
        <ng-container *ngIf="!hasCronologia">
          <h1 class="text-center do3-fw600 text-blue pt-5">
            Al momento non hai nessun appuntamento in archivio. <br />
            Contatta l’Health Manager per prenotare un nuovo Videoconsulto.
            <!--<span class="do3-lightblue-text do3-fw800">
              <a routerLink="/richieste">Home</a>
            </span>
            per prenotare una visita!-->
          </h1>
        </ng-container>
        <div class="scroll-list m-0">
          <table *ngIf="hasCronologia" class="table table-image">
            <thead>
              <tr>
                <!-- <th scope="col"></th> -->
                <th scope="col">Medico</th>
                <th scope="col">Specializzazione</th>
                <th scope="col">ID Appuntamento</th>
                <th scope="col">Data/Ora</th>
                <th scope="col">Stato</th>
                <!-- <th scope="col">Consenso informato</th> -->
                <!-- <th scope="col"></th> -->
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let richiesta of richieste">
                <tr>
                 <!-- <td scope="row align-items-center">
                    <div class="img-profile-container medium big-border">
                      <img
                        [src]="getDoctorAvatar(richiesta)"
                        class="img-profile"
                        [alt]="richiesta.doctor.name"
                      />
                    </div>
                  </td> -->
                  <td>{{ richiesta.doctor.name }}</td>
                  <td>{{ richiesta.specialization.description }}</td>
                  <td>{{ richiesta.crmNo }}</td>
                  <td>{{ richiesta.slot.from | date : "longDate" }}</td>
                  <td>
                    <span class="badge text-white"
                    [ngClass]="{
                      'bg-success': richiesta.status === 'OPEN',
                      'bg-warning': richiesta.status === 'CLOSED',
                      'bg-danger': richiesta.status === 'DELETED'
                    }">{{ richiesta.status }}</span>
                  </td>
              
                  <!-- <td>
                    <div
                      *ngIf="richiesta.informedConsentDate"
                      class="d-flex align-items-center"
                    >
                      <div>
                        Firmato il
                        {{ richiesta.informedConsentDate | date : "shortDate" }}
                      </div>
                    </div>
                    <ng-container *ngIf="!richiesta.informedConsentDate">
                      Non firmato
                    </ng-container>
                  </td> -->
                  <!-- <td> -->
                  <!-- <mat-icon class="dots">more_vert</mat-icon> -->
                  <!-- </td> -->
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

        <ng-container
          *ngIf="richiesteMeta && richiesteMeta.pagination.totalPages > 0"
        >
          <!-- Pagination -->
          <div class="d-flex justify-content-center">
            <div class="col-auto">
              <div class="pagination">
                <a
                  class="page-link"
                  (click)="handlePageClick(0)"
                  [ngClass]="{
                    disabled: page === 0
                  }"
                >
                  <span class="material-icons-outlined">first_page</span>
                </a>
                <a
                  class="page-link"
                  (click)="handlePageClick(page - 1)"
                  [ngClass]="{
                    disabled: page === 0
                  }"
                >
                  <span class="material-icons-outlined">chevron_left</span>
                </a>
                <a
                  class="page-link"
                  (click)="handlePageClick(page + 1)"
                  [ngClass]="{
                    disabled: page === richiesteMeta.pagination.totalPages - 1
                  }"
                >
                  <span class="material-icons-outlined">chevron_right</span>
                </a>
                <a
                  class="page-link"
                  (click)="handlePageClick(richiesteMeta.pagination.totalPages - 1)"
                  [ngClass]="{
                    disabled: page === richiesteMeta.pagination.totalPages - 1
                  }"
                >
                  <span class="material-icons-outlined">last_page</span>
                </a>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </section>
  </div>
</div>
