<footer>
  <div class="container main-container d-flex flex-column">
    <div class="d-flex justify-content-between align-items-start">
      <div
        class="d-flex flex-column flex-lg-row align-items-start align-items-lg-center"
      >
        <img
          class="logo-footer"
          src="./assets/images/logo-drfeel-bianco.png"
          alt="Dr.Feel"
        />
      </div>

      <div>
        <ul class="legal d-flex flex-column align-items-start gap-1">
          <li class="item-copy">
            <a
              target="_black"
              href="https://www.iubenda.com/privacy-policy/54416982/cookie-policy"
              >Cookie Policy</a
            >
          </li>
          <li class="item-copy">
            <a target="_black" href="/assets/docs/DrFeel_TC.pdf"
              >Termini e Condizioni</a
            >
          </li>
          <li class="item-copy">
            <a
              target="_black"
              class=""
              href="/assets/docs/Privacy_Policy_DrFeel.pdf"
              >Privacy Policy</a
            >
          </li>
          <a
            href="https://www.linkedin.com/company/drfeel/mycompany/"
            target="_blank"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C1.46957 18 0.960859 17.7893 0.585786 17.4142C0.210714 17.0391 0 16.5304 0 16V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H16ZM15.5 15.5V10.2C15.5 9.33539 15.1565 8.5062 14.5452 7.89483C13.9338 7.28346 13.1046 6.94 12.24 6.94C11.39 6.94 10.4 7.46 9.92 8.24V7.13H7.13V15.5H9.92V10.57C9.92 9.8 10.54 9.17 11.31 9.17C11.6813 9.17 12.0374 9.3175 12.2999 9.58005C12.5625 9.8426 12.71 10.1987 12.71 10.57V15.5H15.5ZM3.88 5.56C4.32556 5.56 4.75288 5.383 5.06794 5.06794C5.383 4.75288 5.56 4.32556 5.56 3.88C5.56 2.95 4.81 2.19 3.88 2.19C3.43178 2.19 3.00193 2.36805 2.68499 2.68499C2.36805 3.00193 2.19 3.43178 2.19 3.88C2.19 4.81 2.95 5.56 3.88 5.56ZM5.27 15.5V7.13H2.5V15.5H5.27Z"
                fill="white"
              />
            </svg>
          </a>
        </ul>
      </div>
    </div>
    <hr />
    <p class="copyright-mobile">© 2023 drfeel.com All rights reserved</p>
  </div>
</footer>
