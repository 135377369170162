import { Component, OnInit } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import * as fromApp from 'src/app/store/app.reducer';
import { fetchUserInfo } from 'src/app/core/header/store/me.actions';
import { fetchMe } from 'src/app/core/header/store/me.actions';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  public isLoading: boolean = true;
  public emailSent: boolean = false;
  public emailVerified: boolean = false;
  private redirectUrl: string | null = null;
  private productId: string | null = null;
  public error: boolean = false;
  public me: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private auth: Auth,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    // Fetch user
    this.store.dispatch(fetchMe());

    // Check if user is verified
    this.emailVerified = this.auth.currentUser?.emailVerified || false;

    this.route.queryParams.subscribe((params) => {
      this.redirectUrl = params['redirectTo'] || '/';
      this.productId = params['productId'] || null;
    });

    if (this.emailVerified) {
      this.navigateToRedirectUrl();
    } else {
      this.isLoading = false;
    }
  }

  resendVerificationEmail() {
    this.emailSent = false;
    this.http
      .post<any>(`${environment.BFF_PATIENT}/user/send-confirmation-mail`, {})
      .subscribe(
        (response) => {
          this.emailSent = true;
        },
        (error) => {
          console.error("Errore nell'invio dell'email di verifica", error);
        }
      );
  }

  handleContinueButton() {
    this.error = false;
    this.emailSent = false;
    this.isLoading = true;
    this.auth.currentUser?.reload().then(() => {
      this.emailVerified = this.auth.currentUser?.emailVerified || false;
      if (this.emailVerified) {
        this.navigateToRedirectUrl();
      } else {
        this.error = true;
        this.isLoading = false;
      }
    });
  }

  navigateToRedirectUrl() {
    if (this.redirectUrl) {
      const queryParams = this.productId ? { productId: this.productId } : {};
      this.router.navigate([decodeURIComponent(this.redirectUrl)], {
        queryParams,
      });
    } else {
      this.router.navigate(['/riscatta-coupon']);
    }
  }
}
