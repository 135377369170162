<div class="container fatturazione my-5">

  <app-user-info [fetchMe]="true" [size]="'medium'"></app-user-info>

  <div class="col d-flex align-items-center">
    <button class="do3-back-btn col-12 p-0" (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
      Torna indietro
    </button>
  </div>

  <app-loading-spinner *ngIf="loading"></app-loading-spinner>

  <ng-container *ngIf="!loading && allOrders.length == 0">
    <h1 class="text-center do3-fw600 text-blue pt-5">
     Non hai alcun pagamento in sospeso.
    </h1>
  </ng-container>

  <ng-container *ngIf="!loading && allOrders.length > 0">
    <div class="d-md-none super-margin">
      <h1 class="do3-lightblue-text do3-fw800">Pagamenti</h1>
    </div>
    <div class="scroll-div">
      <table class="table do3-gray-text">
        <thead class="do3-fw800 do3-blue-text">
          <tr>
            <th scope="col">Percorso</th>
            <th scope="col">Descrizione</th>
            <th scope="col">id</th>
            <th scope="col">Data</th>
            <th scope="col">Importo</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody class="do3-gray-text">
          <tr *ngFor="let order of allOrders">
            <!-- TODO: Remove this check on id -->
            <!-- DEBUG -->
            <ng-container
              *ngIf="order.id !== '664246d5-e41d-484a-b69d-6fc71c2adbd3'"
            >
              <td scope="row">{{ order.name }}</td>
              <td>{{ order.description }}</td>
              <td>{{ order.number }}</td>
              <!-- <td>05/04/2023</td> -->
              <td>{{ order.lastUpdate | date : "longDate" }}</td>
              <td>
                €
                {{
                  order.paymentInfo.amount -
                    (coupon && coupon !== undefined ? coupon.amount : 0)
                }},00
              </td>
              <td class="last">
                <ng-container *ngIf="order.paymentInfo?.status === 'NEW'">
                  <button
                    class="do3-secondary-btn red cursor-pointer"
                    (click)="payOrder(order.id)"
                  >
                    Non pagato
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="order.paymentInfo?.status === 'PRE_AUTHORIZED'"
                >
                  <button class="do3-secondary-btn green">
                    Preautorizzato
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="order.paymentInfo?.status === 'IN_PROGRESS'"
                >
                  <button
                    class="do3-secondary-btn gray cursor-pointer"
                    (click)="payOrder(order.id)"
                  >
                    Completa pagamento
                  </button>
                </ng-container>
                <ng-container *ngIf="order.paymentInfo?.status === 'FAIL'">
                  <button class="do3-secondary-btn red">
                    Pagamento fallito
                  </button>
                </ng-container>
                <ng-container *ngIf="order.paymentInfo?.status === 'PAID'">
                  <button class="do3-secondary-btn green">Pagato</button>
                </ng-container>
              </td>
            </ng-container>
            <!-- /DEBUG -->
          </tr>
        </tbody>
      </table>
    </div>

    <ng-container
      *ngIf="richiesteMeta && richiesteMeta.pagination.totalPages > 0"
    >
      <!-- Pagination -->
      <div class="d-flex justify-content-center">
        <div class="col-auto">
          <div class="pagination">
            <a
              class="page-link"
              (click)="handlePageClick(0)"
              [ngClass]="{
                disabled: page === 0
              }"
            >
              <span class="material-icons-outlined">first_page</span>
            </a>
            <a
              class="page-link"
              (click)="handlePageClick(page - 1)"
              [ngClass]="{
                disabled: page === 0
              }"
            >
              <span class="material-icons-outlined">chevron_left</span>
            </a>
            <a
              class="page-link"
              (click)="handlePageClick(page + 1)"
              [ngClass]="{
                disabled: page === richiesteMeta.pagination.totalPages - 1
              }"
            >
              <span class="material-icons-outlined">chevron_right</span>
            </a>
            <a
              class="page-link"
              (click)="handlePageClick(richiesteMeta.pagination.totalPages - 1)"
              [ngClass]="{
                disabled: page === richiesteMeta.pagination.totalPages - 1
              }"
            >
              <span class="material-icons-outlined">last_page</span>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
