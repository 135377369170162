import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  TemplateRef,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { startOfDay, endOfDay, isSameDay, isSameMonth } from 'date-fns';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { Visite } from 'src/app/model/Visite';
import * as fromApp from '../../../store/app.reducer';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#0f3564',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-agenda',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AgendaComponent implements OnInit, OnDestroy {
  @ViewChild('modalContent', { static: true }) modalContent:
    | TemplateRef<any>
    | undefined;
  locale = 'it';
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData:
    | {
        action: string;
        event: CalendarEvent;
      }
    | undefined;

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();
  events: CalendarEvent[] = [
    {
      start: new Date(),
      title: 'Disponibile',
      color: colors.green,
      actions: this.actions,
    },
  ];

  activeDayIsOpen = false;

  visite: Visite[] = [];
  tabSubscription!: Subscription;

  constructor(private store: Store<fromApp.AppState>) {}

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    /*this.modal.open(this.modalContent, { size: 'lg' });*/
  }

  setView(view: CalendarView): void {
    this.view = view;
  }

  closeOpenMonthViewDay(): void {
    this.activeDayIsOpen = false;
  }

  getEvent(event: any) {
    // console.log('date:', new Date(event.date_start), 'data');
    // console.log('event:', event);
    /* const timePretty = event.time_start.slice(0, -3);*/
    let title = 'Disponibile';
    let color_event = colors['green'];
    if (event.contact) {
      title = event.contact.user_name + ' - ' + event.subject;
      color_event = colors['red'];
    }
    return {
      start: new Date(event.date_start),
      title: 'Disponibile',
      color: colors['green'],
      actions: this.actions,
    };
  }

  ngOnInit(): void {
    // this.store.dispatch(HomeActions.fetchVisite());
    // this.tabSubscription = this.store
    //   .select('visite')
    //   .pipe(
    //     map((visiteState) =>
    //       visiteState.visite.map((visita) => {
    //         const evento = this.getEvent(visita);
    //         console.log(visiteState.visite);
    //         return evento;
    //       })
    //     )
    //   )
    //   .subscribe((visite: CalendarEvent[]) => {
    //     this.events = visite;
    //     this.refresh.next();
    //   });
  }

  ngOnDestroy(): void {
    if (this.tabSubscription) {
      this.tabSubscription.unsubscribe();
    }
  }
}
