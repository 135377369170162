<div class="vh-100">
  <mat-drawer-container>
    <mat-drawer #sidenav role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-drawer>
    <mat-drawer-content>
      <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
      <div class="decoration-bg vh-100 w-100 position-fixed z-n1">
        <!-- D top right mobile -->
        <svg
          class="d-md-none position-absolute top-0 end-0"
          width="375"
          height="494"
          viewBox="0 0 375 494"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M254.628 -222.714C270.044 -247.111 284.826 -259.496 298.924 -259.785C314.824 -258.522 330.005 -253.552 343.121 -245.222L687.424 -56.0596C694.776 -52.1887 701.822 -47.9243 708.578 -43.2012C713.584 -39.1028 717.837 -34.1814 721.021 -28.6679C728.62 -17.0207 724.317 1.62717 708.137 27.234L637.456 137.903C601.523 195.413 543.429 237.713 475.99 255.439C407.909 276.016 334.775 268.253 275.121 234.018C213.782 201.441 169.954 146.012 153.935 80.5227C135.627 15.6295 146.993 -54.4779 185.201 -112.928L254.585 -222.737L254.628 -222.714ZM330.489 -109.065L293.755 -50.9284C275.481 -21.6405 269.677 12.8696 277.59 45.3083C284.324 79.796 306.732 109.299 338.925 125.975C370.153 144.086 408.397 148.524 444.273 138.177C479.374 129.146 509.684 107.145 528.267 77.1837L564.658 19.5893L330.489 -109.065Z"
            fill="url(#paint0_linear_12_925)"
            fill-opacity="0.34"
          />
          <defs>
            <linearGradient
              id="paint0_linear_12_925"
              x1="620.754"
              y1="165.529"
              x2="183.708"
              y2="-110.622"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F7257D" />
              <stop offset="1" stop-color="#F7F7F7" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>

        <!-- D top right desktop -->
        <svg
          class="d-none d-md-block position-absolute top-0 end-0"
          width="549"
          height="459"
          viewBox="0 0 549 459"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M176.544 -331.548C201.42 -370.918 225.275 -390.904 248.024 -391.37C273.682 -389.331 298.18 -381.312 319.345 -367.87L874.948 -62.6178C886.813 -56.3712 898.183 -49.4898 909.085 -41.8681C917.163 -35.2544 924.027 -27.3128 929.164 -18.4157C941.427 0.379499 934.482 30.4716 908.373 71.7934L794.314 250.381C736.33 343.184 642.584 411.444 533.757 440.049C423.894 473.253 305.878 460.726 209.614 405.481C110.63 352.911 39.9057 263.466 14.0562 157.786C-15.4885 53.0673 2.85325 -60.0654 64.5097 -154.387L176.474 -331.586L176.544 -331.548ZM298.961 -148.153L239.683 -54.3376C210.195 -7.07545 200.829 48.6136 213.598 100.96C224.465 156.613 260.624 204.221 312.574 231.131C362.967 260.359 424.682 267.52 482.574 250.822C539.217 236.249 588.128 200.745 618.116 152.397L676.841 59.4571L298.961 -148.153Z"
            fill="url(#paint0_linear_28_558)"
            fill-opacity="0.34"
          />
          <defs>
            <linearGradient
              id="paint0_linear_28_558"
              x1="767.363"
              y1="294.96"
              x2="62.1006"
              y2="-150.666"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F7257D" />
              <stop offset="1" stop-color="#F7F7F7" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>

        <!-- D bottom left mobile -->
        <svg
          class="d-bottom-left-mobile d-md-none position-absolute"
          width="124"
          height="182"
          viewBox="0 0 124 182"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M81.2684 169.769C75.5498 177.711 70.2918 181.6 65.514 181.409C60.1579 180.655 55.1211 178.661 50.8522 175.573L-61.813 104.479C-64.2224 103.018 -66.52 101.43 -68.71 99.6928C-70.3203 98.2028 -71.6592 96.4497 -72.6238 94.5181C-74.9571 90.4197 -73.1178 84.1957 -67.1157 75.8598L-40.9204 39.8484C-27.5778 21.1186 -7.04549 7.99187 16.1462 3.37493C39.6134 -2.1936 64.2097 1.93427 83.7001 14.7464C103.795 27.0317 117.494 46.6932 121.573 69.1896C126.439 91.5313 121.153 115.029 107.021 134.031L81.2825 169.778L81.2684 169.769ZM57.9209 129.743L71.5478 110.817C78.3342 101.278 81.0065 89.7158 78.9935 78.5732C77.4215 66.761 70.4419 56.3149 59.8866 50.0098C49.6876 43.2384 36.833 40.9517 24.4771 43.7184C12.4101 46.0554 1.69912 52.881 -5.20579 62.6415L-18.7056 81.3905L57.9209 129.743Z"
            fill="url(#paint0_linear_12_932)"
            fill-opacity="0.34"
          />
          <defs>
            <linearGradient
              id="paint0_linear_12_932"
              x1="-34.7002"
              y1="30.8398"
              x2="107.574"
              y2="133.281"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F7257D" />
              <stop offset="1" stop-color="#F7F7F7" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>

        <!-- D bottom left desktop -->
        <svg
          class="d-none d-md-block position-absolute bottom-0 start-0"
          width="358"
          height="450"
          viewBox="0 0 358 450"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M199.869 642.92C178.349 673.404 158.441 688.418 140.219 687.858C119.772 685.176 100.498 677.754 84.1128 666.131L-347.957 399.096C-357.195 393.611 -366.011 387.638 -374.422 381.093C-380.615 375.469 -385.782 368.833 -389.528 361.503C-398.57 345.961 -391.779 322.166 -369.193 290.172L-270.604 151.945C-220.402 80.0626 -142.589 29.2856 -54.3345 10.8579C34.9366 -11.2076 128.858 3.6545 203.622 51.8072C280.672 97.9301 333.597 172.403 349.949 258.026C369.297 343.031 349.982 432.805 296.779 505.752L199.923 642.954L199.869 642.92ZM109.431 491.152L160.71 418.513C186.244 381.904 196.021 337.726 187.949 295.316C181.536 250.338 154.554 210.76 114.087 187.098C74.962 161.645 25.8721 153.384 -21.1367 164.372C-67.0591 173.711 -107.652 200.115 -133.63 237.573L-184.431 309.535L109.431 491.152Z"
            fill="url(#paint0_linear_28_563)"
            fill-opacity="0.34"
          />
          <defs>
            <linearGradient
              id="paint0_linear_28_563"
              x1="-247.21"
              y1="117.378"
              x2="298.86"
              y2="502.873"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#F7257D" />
              <stop offset="1" stop-color="#F7F7F7" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div style="min-height: 100vh">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
