import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsensoComponent } from './consenso.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { ConsensoInformatoComponent } from './consenso-informato/consenso-informato.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ConsensoComponent, ConsensoInformatoComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild([{ path: ':id', component: ConsensoComponent }]),
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
  ],
  exports: [],
})
export class ConsensoModule {}
